<template>
  <a :href="partner.link" class="Partner" target="_blank" :title="partner.name" ref="PartnerRef">
    <BaseVisual :visual="{ src : partner.logo, alt : partner.name }" />
  </a>
</template>

<script>
  import { ref, toRefs, onMounted } from 'vue'
  import BaseVisual from '@components/BaseVisual'

  export default {
    components: { BaseVisual },
    props: ["partner"],
    setup(props) {

      const PartnerRef = ref(null)
      const { partner } = toRefs(props)
      const { color } = partner.value

      onMounted(() => {
        PartnerRef.value.querySelector('.visual__container').style.backgroundColor = color
      })

      return {
        PartnerRef
      }
    }
  }
</script>

<style lang="scss">
  @import "@scss/components/_Partner.scss";
</style>
