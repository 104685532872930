<template>
  <div class="Disco" ref="DiscoBall">
    <div></div>
    <!-- <img src="@/assets/PNG/drawing-disco.gif" /> -->
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue'
  import { gsap } from 'gsap'

  export default {
    setup(){

      const DiscoBall = ref(null)

      onMounted(() => {
        gsap.from(DiscoBall.value, { yPercent: -150, xPercent: -20, rotate: 5, ease: 'power4.out', duration: 1.8 })
      })

      return {
        DiscoBall
      }
    }
  }
</script>

<style lang="scss">
  @import "@scss/components/_Disco.scss";
</style>
