<template>
  <div class="Star">
    <svg width="250" height="250" viewBox="0 0 257.63 257.6">
      <path d="M252.49,135.75,213.34,147.7a98.69,98.69,0,0,0-65.61,65.61l-12,39.14a7.27,7.27,0,0,1-13.9,0L109.9,213.31A98.75,98.75,0,0,0,44.29,147.7L5.14,135.75a7.27,7.27,0,0,1,0-13.9l39.15-12A98.7,98.7,0,0,0,109.9,44.29L121.85,5.14a7.27,7.27,0,0,1,13.9,0l12,39.15a98.66,98.66,0,0,0,65.61,65.58l39.15,12a7.27,7.27,0,0,1,0,13.9"/>
    </svg>
  </div>
</template>

<style lang="scss">
  @import "@scss/components/_Star.scss";
</style>
