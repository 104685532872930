<template>
  <component :is="type" :to="to" class="Link" :class="{ 'is--external' : external, 'is--min' : min }">
    <span class="Link__text">
      <span class="Link__arrow" v-if="external">
        <svg width="10" height="9" viewBox="0 0 9.31 9.04">
          <line x1="2.36" y1="1" x2="8.31" y2="1.1"/>
          <line x1="8.31" y1="1.1" x2="8.2" y2="7.05"/>
          <line x1="1" y1="8.04" x2="7.92" y2="1.41"/>
        </svg>
      </span>
      <span class="txt"><slot /></span>
      <span class="Link__line"></span>
    </span>
  </component>
</template>

<script>
export default {
  props: ["to", "external", "disabled", "min"],
  computed: {
    type() {
      if(this.disabled) {
        return 'span'
      } else if (this.to) {
        return 'router-link'
      } else {
        return 'a'
      }
    }
  }
}
</script>

<style lang="scss">
  @import "@scss/components/_Link.scss";
</style>
