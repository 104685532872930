<template>
  <label class="Darkmode" ref="Darkmode" for="darkmode">
    <input id="darkmode" name="darkmode" type="checkbox" @change="toggleDarkMode" />
    <div class="Darkmode__icon__container">
      <div class="Darkmode__icon">
        <svg width="27" height="27" viewBox="0 0 27 27">
          <circle class="Darkmode__icon__base" cx="13.5" cy="13.5" r="13"/>
          <path d="M24,5.79A13,13,0,1,1,1.52,18.56S17.25,17.75,24,5.79Z"/>
          <circle class="Darkmode__icon__line" cx="13.5" cy="13.5" r="13"/>
        </svg>
      </div>
    </div>
    <div class="inner">
      <span v-if="isDM">
        <span>L</span>
        <span>i</span>
        <span>g</span>
        <span>h</span>
        <span>t</span>
        <span></span>
        <span>M</span>
        <span>o</span>
        <span>d</span>
        <span>e</span>
      </span>
      <span v-else>
        <span>D</span>
        <span>a</span>
        <span>r</span>
        <span>k</span>
        <span> </span>
        <span>M</span>
        <span>o</span>
        <span>d</span>
        <span>e</span>
      </span>
    </div>
  </label>
</template>

<script>
  import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
  import { isDarkMode, useDarkMode } from '@/store/darkmode'
  import { gsap } from 'gsap'

  export default {
    setup() {

      const Darkmode = ref(null)

      const onEnterTL = gsap.timeline({
        defaults: {
          duration: 1.4,
          ease: 'power4.out'
        },
        delay: 1,
        onStart: () => Darkmode.value.classList.add('is--animating'),
        onComplete: () => Darkmode.value.classList.remove('is--animating')
      })

      onMounted(() => {
        const el = Darkmode.value
        onEnterTL.from(el, { x: 40, opacity: 0 }, 0)
        onEnterTL.from(el.querySelector('.Darkmode__icon__container'), { x: -60, rotate: -120 }, 0)
        onEnterTL.from(el.querySelectorAll('.inner > span > span'), { y: 20, clearProps: 'transform', stagger: 0.01 }, 0)
      })

      onBeforeUnmount(() => onEnterTL.kill(true))

      const isDM = computed(() => isDarkMode.value)
      const toggleDarkMode = () => {
        useDarkMode.toggle()
      }

      return {
        Darkmode,
        toggleDarkMode,
        isDM
      }
    }
  }
</script>

<style lang="scss">
  @import "@scss/components/_Darkmode.scss";
</style>
