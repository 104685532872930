import { ref, computed } from 'vue'
import router from '../../router'
import timeline from '@assets/timeline.json'
import { useStars } from '@/store/stars'
import { isTracking } from "vue-gtag-next";
import { useCookies } from '@/store/cookies'

export const currentYear = ref(timeline[0])
export const currentMonth = ref(timeline[0].months.find((month) => month.events.length != 0))
export const nextYear = ref(timeline[1])
export const isYearTransition = ref(false)
export const isYearTransitionFromFutur = ref(false)

export const useTimeline = {
  goToYear(year) {

    if (currentYear.value.year === year.year) return

    router.push({ path: '/' })

    let yearIndex
    currentYear.value = timeline.find((y, index) => {
      yearIndex = index
      if (y.year === year.year) return y
    })

    nextYear.value = yearIndex + 1 === timeline.length ? null : timeline[yearIndex + 1]
    const currentTimelineYearMonth = computed(() => currentYear.value.months.find((month) => month.events.length != 0))
    currentMonth.value = currentTimelineYearMonth.value ? currentTimelineYearMonth.value : null

    // Gtag
    if (isTracking.value) useCookies.track(currentYear.value.year)

  },
  goToMonth(month) {
    currentMonth.value = month
    useStars.randomize()
  },
  getYearFormString(year) {
    let yearFormString = timeline.find(y => y.year === year)
    if (yearFormString === undefined ) yearFormString = timeline[0]
    return yearFormString
  },
  goToNextYear() {

    // Cancel if isYearTransition
    if(isYearTransition.value) return

    const nextYear = Number(currentYear.value.year) + 1

    if (Number(timeline[timeline.length - 1].year) < nextYear) return

    const nextYearTarget = useTimeline.getYearFormString(nextYear.toString())
    useTimeline.goToYear(nextYearTarget)
    isYearTransition.value = true

  },
  goToPrevYear() {

    // Cancel if isYearTransition
    if(isYearTransition.value) return

    const prevYear = Number(currentYear.value.year) - 1

    // Cancel if not in timeline
    if (Number(timeline[0].year) > prevYear) return

    const prevYearTarget = useTimeline.getYearFormString(prevYear.toString())
    useTimeline.goToYear(prevYearTarget)
    isYearTransition.value = true
    isYearTransitionFromFutur.value = true

  }
}
