<template>
  <div class="Home__page">

    <div v-if="!isYearTransition" class="Events" :class="{ 'is--double' : monthEvents && monthEvents.length > 1 }">
      <div v-for="event in monthEvents" :key="event.id" :data-type="event.type">
        <component :is="getType(event)" :event="event"/>
      </div>
    </div>

    <Timeline v-if="!isYearTransition"/>
    <TimelineYearSwitch v-if="!isYearTransition" />
    <TimelineYearNoResults v-if="!isYearTransition && monthEvents == null"/>
    <TimelineYearTransition v-if="isYearTransition" />

  </div>
</template>

<script>
import { useRoute } from 'vue-router'
import { useTimeline, currentMonth } from '@/store/timeline'
import { computed, onMounted, onBeforeUnmount } from 'vue'
import { isMenuVisible, isMenuOpen } from '@/store/menu'
import { isYearTransition } from '@/store/timeline'
import Timeline from '@components/Timeline'
import TimelineYearNoResults from '@components/TimelineYearNoResults'
import TimelineYearSwitch from '@components/TimelineYearSwitch'
import TimelineYearTransition from '@components/TimelineYearTransition'
import EventQuote from '@components/EventQuote'
import EventStories from '@components/EventStories'
import EventPlyr from '@components/EventPlyr'
import EventPicture from '@components/EventPicture'
import EventGalleriePreview from '@components/EventGalleriePreview'

export default {
  components: { Timeline, TimelineYearSwitch, TimelineYearTransition, TimelineYearNoResults, EventQuote, EventStories, EventPlyr, EventPicture, EventGalleriePreview },
  setup() {
    const route = useRoute()
    const { year } = route.params

    // If Year in param
    if (year) {
      const yearFormURL = useTimeline.getYearFormString(year)
      useTimeline.goToYear(yearFormURL)
    }

    const monthEvents = computed(() => currentMonth.value ? currentMonth.value.events : null )

    const getType = (event) => {
      let type

      switch (event.type) {
        case 'quote':
          type = 'EventQuote'
          break
        case 'stories':
          type = 'EventStories'
          break
        case 'video':
          type = 'EventPlyr'
          break
        case 'gallery':
          type = 'EventGalleriePreview'
          break;
        default:
          type = 'EventPicture'
      }
      return type
    }

    const onKeyDown = (event) => {
      switch (event.key) {
        case "ArrowLeft":
          useTimeline.goToPrevYear()
          break
        case "ArrowRight":
          useTimeline.goToNextYear()
          break
      }
    }

    onMounted(() => {
      isMenuVisible.value = true
      window.addEventListener('keydown', onKeyDown)
    })

    onBeforeUnmount(() => {
      isMenuVisible.value = false
      window.removeEventListener('keydown', onKeyDown)
    })

    return {
      isMenuOpen,
      monthEvents,
      getType,
      isYearTransition
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/pages/_Home.scss";
</style>
