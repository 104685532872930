import { ref } from 'vue'
import { useState, useGtag } from "vue-gtag-next";

const { property } = useState()

export const useCookies = {
  enable() {
    property.value = { id: "G-B15L67E1N8" }
    showCookies.value = false
  },
  track(string) {
    const { pageview } = useGtag()
    pageview({ page_title : `20 ans de LillelaNuit - ${string}` })
  },
  getCookie(name) {
    var cookie = document.cookie;
    var prefix = name + "=";
    var begin = cookie.indexOf("; " + prefix);
    if (begin == -1) {
        begin = cookie.indexOf(prefix);
        if (begin != 0) return null;
    } else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
        end = cookie.length;
        }
    }
    return unescape(cookie.substring(begin + prefix.length, end));
  }
}

const hasCookies = useCookies.getCookie('_ga') !== null
if (hasCookies) property.value = { id: "G-B15L67E1N8" }

export const showCookies = ref(!hasCookies)
