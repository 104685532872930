<template>
  <nav id="TimelineNav" class="Timeline__nav" ref="Timeline">
    <div class="inner" @mousedown="mouseDownHandler">
      <TimelineNavLink :key="index" v-for="(year, index) in timelineYears" @click.prevent="updateYear(year)" :class="{ 'is--active' : year.year == currentYear.year }" :year="year"  />
      <span class="soon">La suite sur<br/><Link href="https://www.lillelanuit.com/" :external="true" target="_blank">lillelanuit.com</Link></span>
    </div>
  </nav>
</template>

<script>
  import timeline from '@assets/timeline.json'
  import { ref, onMounted, computed } from 'vue'
  import { currentYear, useTimeline } from '@/store/timeline'
  import { isMenuOpen, useMenu } from '@/store/menu'
  import Link from '@components/Link'
  import TimelineNavLink from '@components/TimelineNavLink'
  import { gsap } from 'gsap'

  export default {
    components: { TimelineNavLink, Link },
    setup() {

      const Timeline = ref(null)

      const timelineYears = computed(() => timeline)
      timelineYears.value.forEach((year) => {
        const { months } = year
        year.eventsCount = 0
        months.forEach((month) => {
          if(month.events) year.eventsCount += month.events.length
        })
      })

      const updateYear = (year) => {
        useMenu.close()
        useTimeline.goToYear(year)
      }

      onMounted(() => {
        gsap.set(Timeline.value, { yPercent: 102 })
        gsap.set(Timeline.value.querySelector('.inner'), { xPercent: -20 })

        // ScrollMouswheel
        Timeline.value.addEventListener('wheel', (e) => Timeline.value.scrollLeft += e.deltaY, {passive: true});
      })

      // Drag drag
      const pos = { left: 0, x:0 }
      const mouseDownHandler = function (e) {
        pos.left = Timeline.value.scrollLeft
        pos.x = e.clientX
        Timeline.value.addEventListener('mousemove', mouseMoveHandler);
        Timeline.value.addEventListener('mouseup', mouseUpHandler);
      }

      const mouseMoveHandler = function (e) {
        const dx = e.clientX - pos.x;
        gsap.to(Timeline.value, { scrollTo : { x : pos.left - dx, autoKill: true }, duration: 1.4, ease: 'power4.out' })

        if (!Timeline.value.classList.contains('is--dragging')) Timeline.value.classList.add('is--dragging')
      }

      const mouseUpHandler = function () {
        Timeline.value.removeEventListener('mousemove', mouseMoveHandler);
        Timeline.value.removeEventListener('mouseup', mouseUpHandler);
        Timeline.value.classList.remove('is--dragging')
      }

      return {
        Timeline,
        timelineYears,
        updateYear,
        currentYear,
        isMenuOpen,
        mouseDownHandler
      }
    }
  }
</script>

<style lang="scss">
  @import "@scss/components/_TimelineNav.scss";
</style>
