<template>
  <header class="Header">

    <LLN />
    <AppNavToggle />
    <Darkmode />
    <AppNav />
    <TimelineNav />
    <Credits />

    <div class="Header__drawings" ref="HeaderDrawings">
      <Drawing name="star" />
      <Drawing name="heart" sprite="true"/>
      <Drawing name="note" sprite="true" />
      <Drawing name="quotes" sprite="true"/>
      <Drawing name="star" />
    </div>
  </header>
</template>

<script>
import LLN from '@components/LLN'
import Darkmode from '@components/Darkmode'
import AppNavToggle from '@components/AppNavToggle'
import AppNav from '@components/AppNav'
import TimelineNav from '@components/TimelineNav'
import Credits from '@components/Credits'
import Drawing from '@components/Drawing'

export default {
  components: {
    LLN,
    Darkmode,
    AppNavToggle,
    AppNav,
    TimelineNav,
    Credits,
    Drawing
  }
}
</script>

<style lang="scss">
  @import "@scss/components/_AppHeader.scss";
</style>
