<template>
  <div class="Nav__container" id="AppNav">
    <nav class="Nav" ref="Nav">
      <div :key="nav.path" v-for="nav in navigation">
        <Link :to="nav.path" class="Nav__link">
          <span>{{ nav.meta.title }}</span>
        </Link>
      </div>
    </nav>
  </div>
</template>

<script>
  import { ref, onMounted } from 'vue';
  import { gsap } from 'gsap'
  import router from "@/router";
  import Link from '@components/Link'

  export default {
    components: { Link },
    setup() {
      const Nav = ref(null)
      const routes = router.getRoutes()
      const navigation = routes.filter(route => route.props && route.props.default == false)

      onMounted(() => {
        gsap.set(Nav.value.querySelectorAll('a'), { yPercent: 102 })
      })

      return {
        Nav,
        navigation
      }
    }
  }
</script>

<style lang="scss">
  @import "@scss/components/_AppNav.scss";
</style>
