<template>
  <div class="Event__page">
    <AppBack />
    <component :is="eventType(event)" :event="event" />
  </div>
</template>

<script>
import { title } from '@assets/config'
import { useRouter, useRoute } from 'vue-router'
import timeline from '@assets/timeline.json'
import { currentMonth, currentYear } from '@/store/timeline'
import AppBack from '@components/AppBack'
import EventQuote from '@components/EventQuote'
import EventStories from '@components/EventStories'
import EventPlyr from '@components/EventPlyr'
import EventPicture from '@components/EventPicture'
import EventGallerie from '@components/EventGallerie'

export default {
  components: { AppBack, EventQuote, EventStories, EventPlyr, EventPicture, EventGallerie },
  setup() {
    const router = useRouter()
    const route = useRoute()
    const { id } = route.params


    const getEventData = (object, key, value) => {
      if (Array.isArray(object)) {
        for (const obj of object) {
          const result = getEventData(obj, key, value);
          if (result) {
            return result;
          }
        }
      } else {
        if (Object.prototype.hasOwnProperty.call(object, key) && object[key] === value) {
          return object;
        }

        for (const k of Object.keys(object)) {
          if (typeof object[k] === "object") {
            const o = getEventData(object[k], key, value);
            if (o !== null && typeof o !== 'undefined')
              return o;
          }
        }
        return null;
      }
    }

    const event = getEventData(timeline, 'id', id) ?? null

    // Home si NoData else get Current Year / Month
    if(event == null) router.push('/')
    else {
      const eventYear = timeline.find(year => year.year == event.id.split('-')[0])
      const eventMonth = eventYear.months[Number(event.id.split('-')[1] - 1)]
      currentYear.value = eventYear
      currentMonth.value = eventMonth

      document.title = `${title} - ${event.title.replace('<br>', ' ')}`
    }

    const eventType = (event) => {

      let type
      if (event == null) return

      switch (event.type) {
        case 'quote':
          type = 'EventQuote'
          break
        case 'story':
          type = 'EventStories'
          break
        case 'video':
          type = 'EventPlyr'
          break
        case 'gallery':
          type = 'EventGallerie'
          break;
        case 'picture':
          type = 'EventPicture'
          break;
        default:
          type = null
      }

      return type
    }

    return {
      event,
      eventType
    }

  }
}
</script>

<style lang="scss">
  @import "@/scss/pages/_Event.scss";
</style>
