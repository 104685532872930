<template>
  <div class="Contact__page">
    <AppBack />
    <div class="Contact__links" ref="ContactLinks">
      <div :key="link.url" v-for="link in config.contact">
        <Link :href="link.url" :external="link.external" :target="link.target">{{ link.title }}</Link>
      </div>
    </div>
    <Socials ref="Socials" :socials="config.socials"/>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount, watch } from 'vue'
import { gsap } from 'gsap'
import config from '@assets/config'
import { isMenuOpen } from '@/store/menu'
import Link from '@components/Link'
import AppBack from '@components/AppBack'
import Socials from '@components/Socials'

export default {
  components: {
    AppBack,
    Link,
    Socials
  },
  setup() {

    const ContactLinks = ref(null)
    const Socials = ref(null)

    const onEnterTL = gsap.timeline({
      defaults: {
        duration: 1.2,
        ease: 'power4.out'
      },
      delay: 0.5,
      onStart: () => {
        ContactLinks.value.querySelector('a.is--external .Link__line').classList.add('is--animating')
      },
      onComplete: () => {
        ContactLinks.value.querySelector('a.is--external .Link__line').classList.remove('is--animating')
      }
    })

    onMounted(() => {
      onEnterTL.from(ContactLinks.value.querySelectorAll('a'), { yPercent: 101, stagger:0.02 }, 0)
      onEnterTL.from(ContactLinks.value.querySelector('a.is--external .Link__line'), { scaleX: 0 }, isMenuOpen.value ? 0 : 0.3)
      onEnterTL.from(Socials.value.$el.querySelectorAll('a'), { y: 40, opacity: 0, stagger:0.02 }, isMenuOpen.value ? 0 : 0.3)
    })

    onBeforeUnmount(() => {
      onEnterTL.kill(true)
    })

    const onToggleMenu = () => {
      isMenuOpen.value ? onEnterTL.timeScale(2).delay(0).reverse(1) : onEnterTL.timeScale(1).restart(true).delay(0.6)
    }

    watch(isMenuOpen, onToggleMenu)

    return {
      config,
      ContactLinks,
      Socials,
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/pages/_Contact.scss";
</style>
