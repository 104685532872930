import { ref } from 'vue'
import Sniffer from 'snifferjs'
import { useBreakpoints } from '@vueuse/core'

export const sniff = ref(new Sniffer(navigator.userAgent));
export const isTouch = ref(false)

export const useSniffer = {
  init() {
    const htmlNode = document.getElementsByTagName('html')
    const classNameArr = htmlNode[0].className ? [htmlNode[0].className] : []

    sniff.value.browser.name && classNameArr.push(sniff.value.browser.name)
    sniff.value.browser.engine && classNameArr.push(sniff.value.browser.engine)
    sniff.value.os.name && classNameArr.push(sniff.value.os.name)

    for (const prop in sniff.value.features) {
      if (sniff.value.features[prop]) classNameArr.push(prop)
    }

    // touch Class
    if ('ontouchstart' in window || navigator.MaxTouchPoints > 0 || navigator.msMaxTouchPoints > 0) {
      classNameArr.push('touch')
      isTouch.value = true
    }

    // Add Classes
    htmlNode[0].className = classNameArr.join(' ')
  }
}

const breakpoints = useBreakpoints({
  mobile: 768,
  tablet: 992,
  laptop: 1024,
  desktop: 1280,
})

export const isMobile = breakpoints.smaller('mobile')
export const isTabletSmall = breakpoints.smaller('tablet')
