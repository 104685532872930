<template>
  <article class="Event__picture is--waiting" ref="EventPicture" :data-event="event.id">

    <component :is="type" class="Event__picture__visual" :target="type === 'a' ? '_blank' : null"  :href="event.photo.link ? event.photo.link : null">
      <BaseVisual :visual="{ src : event.photo.src, alt: event.title }"/>
      <div class="shadow"></div>
      <span v-if="event.photo.copyright" class="Event__picture__copyright">{{event.photo.copyright}}</span>
    </component>

    <component v-if="event.title" class="Event__picture__content" :is="type" :target="type === 'a' ? '_blank' : null"  :href="event.photo.link ? event.photo.link : null" ref="EventContent">
      <h1 v-html="event.title"/>
      <div class="text" v-html="event.photo.desc"></div>
      <span v-if="type === 'a'" class="Link__arrow">
        <svg width="10" height="9" viewBox="0 0 9.31 9.04">
          <line x1="2.36" y1="1" x2="8.31" y2="1.1"/>
          <line x1="8.31" y1="1.1" x2="8.2" y2="7.05"/>
          <line x1="1" y1="8.04" x2="7.92" y2="1.41"/>
        </svg>
      </span>
    </component>

    <div class="Event__picture__drawings" ref="EventDrawings">
      <Drawing v-for="drawing in eventDrawings.slice(0, 2)" :key="drawing" :name="drawing" :sprite="drawing === 'thunderbolt' || 'heart'" />
    </div>

  </article>
</template>

<script>
  import { ref, toRefs, watch, computed, onMounted, onBeforeUnmount } from 'vue'
  import { isMenuOpen } from '@/store/menu'
  import { gsap } from 'gsap'
  import BaseVisual from '@components/BaseVisual'
  import Drawing from '@components/Drawing'

  export default {
    props: ["event"],
    components: { BaseVisual, Drawing },
    setup(props) {

      const EventPicture = ref(null)
      const EventContent = ref(null)
      const EventDrawings = ref(null)
      const eventDrawings = gsap.utils.shuffle(['star', 'thunderbolt', 'note', 'heart'])

      const { event } = toRefs(props)

      const type = computed(() => event.value.photo.link ? 'a' : 'div' )

      const onEnterTL = gsap.timeline({
        defaults: {
          duration: 1.8,
          ease: 'power4.out',
          clearProps: 'all'
        },
        delay: 0.2,
        repeatRefresh:true,
        onComplete: () => EventPicture.value.classList.remove('is--waiting'),
        onReverse: () => EventPicture.value.classList.add('is--waiting')
      })

      onMounted(() => {

        // Setup Drawings
        const drawings = EventDrawings.value.querySelectorAll(':scope > .Drawing')
        let drawingPrevLeft = gsap.utils.random([false, true])
        drawings.forEach(d => {
          const top = gsap.utils.random(-5, 100, 1)
          const left = [gsap.utils.random(-30, -10, 1), gsap.utils.random(110, 120, 1)]

          d.style.top = `${ top }%`
          d.style.left = `${ drawingPrevLeft ? left[0] : left[1] }%`

          drawingPrevLeft = !drawingPrevLeft

        })

        onEnterTL.from(EventPicture.value, { y: window.innerHeight * 1.5, rotate: () => gsap.utils.random(-35, 35) }, 0)
        onEnterTL.from(drawings, { y: window.innerHeight * 0.5, rotate: -40, clearProps: 'none', stagger: 0.05 }, 0)
        if(EventContent.value) onEnterTL.from(EventContent.value, { yPercent: 100, rotate: () => gsap.utils.random(-35, 35) }, 0.2)
      })

      onBeforeUnmount(() => {
        onEnterTL.kill(true)
      })

      // ToggleMenu
      const onToggleMenu = () => {
        if (isMenuOpen.value) onEnterTL.timeScale(2).reverse(1)
        else onEnterTL.timeScale(1).restart(true)
      }

      watch(isMenuOpen, onToggleMenu)

      return {
        EventPicture,
        EventDrawings,
        eventDrawings,
        EventContent,
        type
      }
    }
  };
</script>

<style lang="scss">
  @import "@scss/components/_EventPicture.scss";
</style>
