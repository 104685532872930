<template>
  <div class="Stars" ref="Stars">
    <Star />
    <Star />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { useStars } from '@/store/stars'
import Star from '@components/Star'
import { gsap } from 'gsap'

export default {
  components: {
    Star
  },
  setup() {

    const Stars = ref(null)

    onMounted(() => {
      gsap.set(Stars.value.querySelector('.Star:nth-child(1)'), { yPercent: 200 , xPercent: -200, rotate: 360 })
      gsap.set(Stars.value.querySelector('.Star:nth-child(2)'), { yPercent: -200 , xPercent: 200, rotate: -360 })
      useStars.randomize('power4.out')
    })

    return {
      Stars
    }
  }
}
</script>

<style lang="scss">
  @import "@scss/components/_AppStars.scss";
</style>
