<template>
  <blockquote class="Event__quote" ref="EventQuote" :data-event="event.id">
    <a :href="event.quote.link" :target="isBlank">
      <Drawing name="quotes" sprite="true"/>
      <div class="inner">
        <div v-html="event.quote.text" ref="EventQuoteText" />
        <span class="author">
          <span ref="EventAutor">{{ event.quote.author }}</span>
        </span>
        <Link :disabled="true" :external="true" :min="true" ref="EventLink" >
          {{ event.quote.link_title }}
        </Link>
      </div>
    </a>
  </blockquote>
</template>

<script>
  import { ref, toRefs, computed, watch, onMounted, onBeforeUnmount } from 'vue'
  import { isMenuOpen } from '@/store/menu'
  import Drawing from '@components/Drawing'
  import Link from '@components/Link'
  import { gsap } from 'gsap'

  export default {
    props: ["event"],
    components : { Drawing, Link },
    setup(props) {

      const EventQuote = ref(null)
      const EventQuoteText = ref(null)
      const EventAutor = ref(null)
      const EventLink = ref(null)

      const { event } = toRefs(props)
      const isBlank = computed(() => event.value.quote.external ? "_blank" : "_self" )

      const onEnterTL = gsap.timeline({
        defaults: {
          duration: 1.8,
          ease: 'power4.out'
        },
        delay: 0.2,
      })

      onMounted(() => {
        onEnterTL.from(EventQuote.value, { y: window.innerHeight * 1.5, rotate: () => gsap.utils.random(-25, 25) }, 0)
        onEnterTL.from(EventQuote.value.querySelector('.Drawing'), { xPercent: -100, yPercent: 50, scale: 0.5, duration: 2.6 }, 0)
        onEnterTL.from(EventAutor.value, { yPercent: 102 }, 0.3)
        onEnterTL.from(EventLink.value.$el, { y: 102 }, 0.3)
      })

      onBeforeUnmount(() => {
        onEnterTL.kill(true)
      })

      // ToggleMenu
      const onToggleMenu = () => isMenuOpen.value ? onEnterTL.timeScale(2).reverse(1) : onEnterTL.timeScale(1).restart(true)
      watch(isMenuOpen, onToggleMenu)

      return {
        EventQuote,
        EventQuoteText,
        EventAutor,
        EventLink,
        isBlank
      }

    }
  }
</script>

<style lang="scss">
  @import "@scss/components/_EventQuote.scss";
</style>
