<template>
  <div class="Arrow">
    <svg width="36" height="38" viewBox="0 0 36.18 36.18">
      <line x1="1" y1="1" x2="35.18" y2="35.18"/>
      <line x1="35.18" y1="18.36" x2="35.18" y2="35.18"/>
      <line x1="18.36" y1="35.18" x2="35.18" y2="35.18"/>
    </svg>
  </div>
</template>

<style lang="scss">
  @import "@scss/components/_Arrow.scss";
</style>
