import { gsap } from 'gsap'

export const useStars = {
  randomize(ease='power4.inOut') {

    const app = document.getElementById('app')
    const stars = app.querySelectorAll('.Stars .Star')

    if (stars.length === 0) return

    const animStars = gsap.timeline({
      paused: true,
      defaults: {
        duration: ease === 'power4.inOut' ? 1.8 : 3.2,
        ease: ease,
        overwrite: true
      },
    })

    animStars.to(stars[0], { yPercent: () => gsap.utils.random(25, 75), xPercent: () => gsap.utils.random(-75, 100), rotate: () => gsap.utils.random(-120, 120) }, 0)
    animStars.to(stars[1], { yPercent: () => gsap.utils.random(-75, -25), xPercent: () => gsap.utils.random(-100, 75), rotate: () => gsap.utils.random(-120, 120) }, 0)
    animStars.play()

  }
}
