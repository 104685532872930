<template>
  <div class="Event__plyr__container" ref="EventPlyrContainer" :data-event="event.id">
    <WknPlyr :event="event" :youtube="event.video.id" :poster="event.video.cover" ref="EventPlyr"></WknPlyr>
  </div>
</template>

<script>
  import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
  import { isMenuOpen } from '@/store/menu'
  import { gsap } from 'gsap'
  import WknPlyr from '@components/WknPlyr'

  export default {
    props: ["event"],
    components : { WknPlyr },
    setup() {

      const EventPlyrContainer = ref(null)
      const EventPlyr = ref(null)

      const onEnterTL = gsap.timeline({
        defaults: {
          duration: 1.8,
          ease: 'power4.out'
        },
        delay: 0.2,
      })

      onMounted(() => {
        onEnterTL.from(EventPlyrContainer.value, { y: window.innerHeight, rotate: () => gsap.utils.random(-15, 15) }, 0)
        onEnterTL.from(EventPlyr.value.plyrDrawings.querySelectorAll('.Drawing'), { yPercent: 102, stagger: { amount: 0.25 }, duration: 1, clearProps: 'all', onComplete: () => EventPlyr.value.plyrDrawings.classList.add('is--showing') }, 0.2)
        onEnterTL.from(EventPlyr.value.plyrSubtitle.querySelector('span'), { yPercent: 102 }, 0.5)
        onEnterTL.from(EventPlyr.value.plyrTitle.querySelector('span'), { yPercent: 102 }, 0.5)
        onEnterTL.from(EventPlyr.value.plyrDuration, { y: -20, opacity: 0, ease: 'power3.out' }, 0.8)
      })

      onBeforeUnmount(() => {
        onEnterTL.kill(true)
      })

      // ToggleMenu
      const onToggleMenu = () => {
        if(isMenuOpen.value) {
          if (EventPlyr.value.WknPlyr.playing) EventPlyr.value.WknPlyr.pause()
          onEnterTL.timeScale(1.4).reverse(1)
        } else {
          onEnterTL.timeScale(1).restart(true)
        }
      }
      watch(isMenuOpen, onToggleMenu)

      return {
        EventPlyrContainer,
        EventPlyr
      }

    }
  }
</script>

<style lang="scss">
  @import "@scss/components/_EventPlyr.scss";
</style>
