<template>
  <button class="Team__toggle" ref="TeamToggle" @click="toggleDisplay">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </button>
</template>

<script>
  import { teamDisplay } from '@/store/team'
  import { isMenuOpen } from '@/store/menu'
  import { useStars } from '@/store/stars'
  import { gsap } from 'gsap'
  import { ref, watch, onMounted, onBeforeUnmount } from 'vue'

  export default {
    setup() {

      //Ref
      const TeamToggle = ref(null)


      //Anims
      const onEnterGridTL = gsap.timeline({
        defaults: {
          duration: 2.4,
          ease: 'power4.out'
        },
        paused: true,
        onStart: () => {
          if (onMenuOpenTL.isActive()) onMenuOpenTL.kill(true)
          if (onEnterSwiperTL.isActive()) onEnterSwiperTL.kill(true)
          TeamToggle.value.classList.remove('is--swiper')
          gsap.set(TeamToggle.value.querySelectorAll(':scope > div'), { opacity: 0 })
        }
      })

      const onEnterSwiperTL = gsap.timeline({
        defaults: {
          duration: 2.4,
          ease: 'power4.out'
        },
        paused: true,
        onStart: () => {
          if (onMenuOpenTL.isActive()) onMenuOpenTL.kill(true)
          if (onEnterGridTL.isActive()) onEnterGridTL.kill(true)
          TeamToggle.value.classList.add('is--swiper')
          gsap.set(TeamToggle.value.querySelectorAll(':scope > div'), { opacity: 0 })
        }
      })

      const onMenuOpenTL = gsap.timeline({
        defaults: {
          duration: 0.4,
          ease: 'power4.in',
        },
        onStart: () => {
          if (onEnterSwiperTL.isActive()) onEnterSwiperTL.kill(true)
          if (onEnterGridTL.isActive()) onEnterGridTL.kill(true)
        },
        overwrite: true,
        paused: true
      })

      onMounted(() => {
        onEnterGridTL.fromTo(TeamToggle.value, { yPercent: 100, xPercent: 100, rotate: -20 }, { yPercent: 0, xPercent: 0, rotate: 0, duration: 1.6 }, 0);
        onEnterGridTL.fromTo(TeamToggle.value.querySelectorAll(':scope > div'), { y: 20, x: 0, opacity: 0 }, { y: 0, x: 0, opacity: 1, stagger: { amount: 0.2, ease: 'power4.out' } }, 0);

        onEnterSwiperTL.fromTo(TeamToggle.value, { yPercent: -100, xPercent: 100, rotate: -10 }, { yPercent: 0, xPercent: 0, rotate: -10, duration: 1.6 }, 0);
        onEnterSwiperTL.fromTo(TeamToggle.value.querySelectorAll(':scope > div'), { y: 0, x: 50, opacity: 0 }, { y:0, x: 0, opacity: 1, stagger: { amount: 0.2, ease: 'power4.out' } }, 0);

        onMenuOpenTL.to(TeamToggle.value.querySelectorAll(':scope > div'), { opacity: 0, stagger: -0.03 }, 0);

        teamDisplay.value == 'grid' ? onEnterSwiperTL.play() : onEnterGridTL.play()
      })

      onBeforeUnmount(() => {
        onEnterGridTL.kill(true)
        onEnterSwiperTL.kill(true)
        onMenuOpenTL.kill(true)
      })

      // ToggleDisplay
      const toggleDisplay = () => {
        if (teamDisplay.value === 'grid') teamDisplay.value = 'swiper'
        else teamDisplay.value = 'grid'
        useStars.randomize('power4.out')
      }

      // ToggleMenu
      const onToggleMenu = () => isMenuOpen.value ? onMenuOpenTL.restart(true) : teamDisplay.value === 'grid' ? onEnterSwiperTL.restart(true) : onEnterGridTL.restart(true)
      const onToggleDisplay = () => teamDisplay.value === 'grid' ? onEnterSwiperTL.restart(true) : onEnterGridTL.restart(true)

      watch(isMenuOpen, onToggleMenu)
      watch(teamDisplay, onToggleDisplay)

      return {
        TeamToggle,
        toggleDisplay
      }

    }
  }
</script>

<style lang="scss">
  @import "@scss/components/_TeamToggle.scss";
</style>
