<template>
  <div id="Cookies" class="Cookies__bar" ref="cookieBar">
    <div class="inner">
      <p><span class="emoji">🍪</span> Ce site utilise des cookies d'anniversaire <span class="emoji">🥳</span> pour voir un peu quelles années vous intéressent le plus. Si ça vous va c'est super. <span class="emoji">🎉</span></p>
      <button id="CookiesYesSir" @click.prevent="enableCookies"><span>Je suis cool avec ça</span></button>
      <button id="CookiesOQueNon" @click.prevent="disableCookies"><span>Alors là, non !</span></button>
    </div>
  </div>
</template>

<script>
  import { gsap } from 'gsap'
  import { ref, onMounted, onBeforeUnmount } from 'vue'
  import { useCookies, showCookies } from '@/store/cookies'

  export default {
    setup() {

      const cookieBar = ref(null)

      const enableCookies = () => {
        useCookies.enable()
      }

      const disableCookies = () => {
        showCookies.value = false
      }

      const onEnterTL = gsap.timeline({
        defaults: {
          duration: 1.8,
          ease: 'power4.out'
        },
        delay: 0.5,
        onStart: () => {},
        onComplete: () => {}
      })

      onMounted(() => {
        onEnterTL.from(cookieBar.value, { yPercent: 140 })
      })

      onBeforeUnmount(() => {
        onEnterTL.kill(true)
      })

      return {
        cookieBar,
        enableCookies,
        disableCookies
      }
    }
  }
</script>

<style lang="scss">
  @import "@scss/components/Cookies.scss";
</style>
