<template>
  <router-link to="/" class="Back" ref="BackRef">
    <span>Retour</span>
  </router-link>
</template>

<script>
  import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
  import { isMenuOpen } from '@/store/menu'
  import { gsap } from 'gsap'

  export default {
    setup() {
      const BackRef = ref(null)

      const onEnterTL = gsap.timeline({
        defaults: {
          duration: 1.4,
          ease: 'power4.out'
        },
        delay: 1.2
      })

      onMounted(() => {
        const el = BackRef.value.$el
        onEnterTL.fromTo(el, { y: -80 }, { y: 0}, 0)
        onEnterTL.fromTo(el.querySelector(':scope > span'), { y: 40 }, { y: 0, duration: 1.6 }, 0)
      })

      onBeforeUnmount(() => onEnterTL.kill(true))

      const onToggleMenu = () => {
        isMenuOpen.value ? onEnterTL.timeScale(3).delay(0).reverse(1) : onEnterTL.timeScale(1).delay(1).restart(true)
      }

      watch(isMenuOpen, onToggleMenu)

      return {
        BackRef
      }
    }
  }
</script>

<style lang="scss">
  @import "@/scss/components/_AppBack.scss";
</style>
