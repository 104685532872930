<template>

  <Drawing :name="getHandsPosition()" ref="Hand" />

</template>

<script>
  import { ref } from 'vue'
  import { onMounted } from '@vue/runtime-core'
  import { gsap } from 'gsap'
  import Drawing from '@components/Drawing'

  export default {
    props: ['index', 'isLeft', 'isRight', 'fromPage'],

    components: { Drawing },

    setup(props){

      const Hands = [ 'hand', 'hand', 'hand-rock', 'hand', 'hand', 'hand-rock-2', 'hand', 'hand' ]

      gsap.utils.shuffle(Hands)

      const getHandsPosition = () => {
        return gsap.utils.random(Hands)
      }

      // Refs
      const Hand = ref(null)

      onMounted(() => {
        // Random position for each hand
        gsap.set(Hand.value.$el, { maxWidth: () => gsap.utils.random(100, 140, 1), scaleX : gsap.utils.random([-1, 1, 1, 1]) })

        gsap.fromTo(Hand.value.$el, {
          yPercent: 100,
          rotate: () => gsap.utils.random(-20, 20),
        }, {
          yPercent: () => gsap.utils.random(0, 20),
          rotate: () => gsap.utils.random(-10, 10),
          delay: () => props.fromPage ? gsap.utils.random(0.5, 0.75, 0.05) : 0,
          duration: 1.4,
          ease: 'power4.out'
        })
      })

      return {
        Hands,
        Hand,
        getHandsPosition
      }
    }
  }
</script>
