// import { isDEVMODE } from '@/store'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const inView = {
  mounted(el) {
   el.setAttribute('data-inview', "")
   el.classList.add('from--top')
   el.inViewST = ScrollTrigger.create({
     trigger: el,
     scroller: window,
    //  markers: isDEVMODE,
     scrub: true,
     onEnter: () => el.classList.remove('from--top'),
     onEnterBack: () => el.classList.remove('from--btm'),
     onLeave: () => el.classList.add('from--btm'),
     onLeaveBack: () => el.classList.add('from--top'),
     onToggle: ({isActive}) => {
       isActive ? el.classList.add('is--visible') : el.classList.remove('is--visible')
     }
   })
 },
 unmounted(el) {
   el.inViewST.kill(true)
 }

}

export default inView
