<template>
  <div class="History__page">
    <div class="container">
      <AppBack />
      <div class="History__dates" ref="PageDates">
        <span>2001</span>
        <span>2021</span>
        <Star />
        <Arrow />
      </div>
      <div class="Page__intro" ref="PageIntro" v-html="data.intro" />
      <div class="Page__visual" ref="PageVisual">
        <BaseVisual :visual="{ 'src' : data.photo }" />

        <div class="Page__thunderbolts" ref="PageThunderbolts">
          <Drawing name="thunderbolt" sprite="true" />
          <Drawing name="thunderbolt" sprite="true" />
        </div>

        <div class="Page__stars" ref="PageStars">
          <Drawing name="star" />
          <Drawing name="star" />
          <Drawing name="star" />
        </div>
      </div>
      <div class="Page__content" ref="PageContent">
        <p v-html="data.content"></p>
      </div>
      <PartnersGrid />
    </div>
  </div>
</template>

<script>
import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
// import { isDEVMODE } from '@/store'
import { isMenuOpen } from '@/store/menu'
import data from '@assets/history'
import AppBack from '@components/AppBack'
import BaseVisual from '@components/BaseVisual'
import PartnersGrid from '@components/PartnersGrid'
import Star from '@components/Star'
import Arrow from '@components/Arrow'
import Drawing from '@components/Drawing'
import { gsap } from 'gsap'
import { SplitText } from 'gsap/SplitText'
import { ScrollTrigger } from 'gsap/ScrollTrigger'

export default {
  components: { AppBack, BaseVisual, PartnersGrid, Star, Arrow, Drawing },
  setup() {

    const PageDates = ref(null)
    const PageIntro = ref(null)
    const PageVisual = ref(null)
    const PageContent = ref(null)
    const PageThunderbolts = ref(null)
    const PageStars = ref(null)
    let splitDate

    const onEnterTL = gsap.timeline({
      defaults: {
        duration: 1.8,
        ease: 'power4.out'
      },
      delay: 0.5
    })

    let inViewVisualST
    let starsRotation
    const onEnterVisualTL = gsap.timeline({
      defaults: {
        duration: 2.6,
        ease: 'power4.out'
      },
      paused: true,
    })


    onMounted(() => {

      splitDate = new SplitText(PageDates.value, { type: 'chars' })

      onEnterTL.from(PageDates.value, { y: 400, rotate: 60, opacity: 0 }, 0)
      onEnterTL.from(splitDate.chars, { yPercent: 102, stagger:0.03 }, 0.6)
      onEnterTL.from(PageDates.value.querySelector('.Star'), { y: -100, x: 100, rotate: -360, opacity: 0}, 0.6)
      onEnterTL.fromTo(PageDates.value.querySelector('.Arrow'), { y: -400, x: -400}, { y: 0, x: 0}, 0)
      onEnterTL.fromTo(PageDates.value.querySelector('.Arrow line:nth-child(1)'), { y: -100, x: -100 }, { y: 0, x: 0 }, 0)
      onEnterTL.fromTo(PageDates.value.querySelector('.Arrow line:nth-child(2)'), { scaleY: 0 }, { scaleY: 1}, 0.4)
      onEnterTL.fromTo(PageDates.value.querySelector('.Arrow line:nth-child(3)'), { scaleX: 0 }, { scaleX: 1}, 0.4)
      onEnterTL.from(PageIntro.value, { y: 120, opacity: 0 }, 0.3)
      onEnterTL.from(PageContent.value, { y: 120, opacity: 0 }, 0)

      inViewVisualST = ScrollTrigger.create({
        trigger: PageVisual.value,
        scroller: document.getElementById('AppContent'),
        // markers: isDEVMODE,
        scrub: true,
        once: true,
        onEnter: () => onEnterVisualTL.restart(true)
      })

      onEnterVisualTL.from(PageVisual.value.querySelector('.visual__container'), { yPercent: 102, rotate: 10 }, 0)
      onEnterVisualTL.from(PageVisual.value.querySelector('.visual__wrapper'), { yPercent: -50, scale: 1.2 }, 0)
      onEnterVisualTL.from(PageThunderbolts.value.querySelectorAll('.Drawing'), { xPercent: 90, yPercent: -80, opacity: 0, stagger: { each: 0.1 }, duration: 1 }, 0.5)

      starsRotation = gsap.from(PageStars.value.querySelectorAll('.Drawing'), {
        scrollTrigger: {
          trigger: PageVisual.value,
          endTrigger: PageContent.value,
          start: 'top bottom',
          end: 'top top',
          scroller: document.getElementById('AppContent'),
          // markers: true,
          // once: true,
          scrub: 1
        },
        xPercent: -50,
        rotate: -250,
        // stagger: { each: 0.1 },
        ease: 'linear'
      })

    })

    onBeforeUnmount(() => {
      inViewVisualST.kill(true)
      onEnterVisualTL.kill(true)
      onEnterTL.kill(true)
      splitDate.revert()
      splitDate = null
    })

    const onToggleMenu = () => {
      if(isMenuOpen.value) {
        onEnterTL.timeScale(2).delay(0).reverse(1)
        if (inViewVisualST.isActive && inViewVisualST.isActive) gsap.to(PageVisual.value, { opacity: 0, yPercent: 100, rotation: -10, overwrite: true, ease: 'power4.inOut', duration: 1.2 })
      } else {
        onEnterTL.timeScale(1).restart(true).delay(0.6)
        gsap.set(PageVisual.value, { clearProps: 'all' })
      }
    }

    watch(isMenuOpen, onToggleMenu)

    return {
      data,
      PageDates,
      PageIntro,
      PageVisual,
      PageContent,
      PageThunderbolts,
      PageStars,
      starsRotation
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/pages/_History.scss";
</style>
