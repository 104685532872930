<template>
  <transition mode="out-in" @enter="onEnter" @leave="onLeave">
    <slot />
  </transition>
</template>

<script>
  import router from "@/router";
  import { ref } from "vue";
  import { gsap } from 'gsap'

  export default {

    setup() {
      const enterTransition = ref('default')
      const leaveTransition = ref('default')

      router.beforeEach((to, from) => {
        enterTransition.value = to.meta.transition ? to.meta.transition : 'default'
        leaveTransition.value = from.meta.transition ? from.meta.transition : 'default'
      })

      return {
        enterTransition,
        leaveTransition
      }
    },
    methods: {
      onEnter(el, done) {

        const pageIn = gsap.timeline({
          defaults: {
            duration: 1.2,
            ease: 'power3.inOut',
            clearProps: 'all',
          },
          onStart: () => {
            document.body.classList.remove('is--loading')
            window.scrollTo(0, 0)
          },
          onComplete: () => done()
        })

        // Transitions
        if(this.enterTransition == 'about') this.aboutEnter(pageIn, el)
        else this.defaultEnter(pageIn, el)


      },
      onLeave(el, done) {

        const pageOut = gsap.timeline({
          defaults: {
            duration: 0.6,
            ease: 'power3.inOut'
          },
          onStart: () => document.body.classList.add('is--loading'),
          onComplete: () => done()
        })

        // Transitions
        if(this.leaveTransition == 'about') this.aboutLeave(pageOut, el)
        else this.defaultLeave(pageOut, el)

      },

      // Default
      defaultEnter(tl, el) {
        tl.from(el, { opacity: 0 })
      },
      defaultLeave(tl, el) {
        tl.to(el, { opacity: 0 })
      },


      // About
      aboutEnter(tl, el) {
        tl.from(el, { opacity: 0, yPercent: 10, ease: 'power3.out', clearProps: 'all' })
      },
      aboutLeave(tl, el) {
        tl.to(el, { yPercent: -10, opacity: 0 })
      }
    }
  }
</script>
