import "lazysizes"
import "lazysizes/plugins/unveilhooks/ls.unveilhooks"

import VueGtag from "vue-gtag-next"
import { createApp } from 'vue'
import inView from "./directives/inView.js"
import prllx from "./directives/prllx.js"
import App from './App.vue'
import router from './router'

// Signature Wokine
console.log('%cCreated by Wokine, with' + '%c ❤', 'color: #FFF padding: 8px 3px 8px 10px background:#000 border-radius:3px 0px 0px 3px', 'color: #71d1c2 padding: 8px 10px 8px 3px background:#000 border-radius: 0px 3px 3px 0px')
console.log('%c⚡' + '%c wokine.com ' + '%c⚡', 'color: #000 padding: 8px padding-right: 2px background:#71d1c2 border-radius:3px 0px 0px 3px', 'color: #FFF padding: 8px 2px background:#71d1c2', 'color: #000 padding: 8px padding-left:2px background:#71d1c2 border-radius:0px 3px 3px 0px')

const app = createApp(App)
app.use(router)
app.use(VueGtag)
app.directive("inview", inView)
app.directive("prllx", prllx)
router.isReady().then(() => {
  app.mount('#app')
})
