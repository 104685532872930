import { ref } from 'vue'
import { gsap } from 'gsap'
import { ScrollToPlugin } from 'gsap/ScrollToPlugin'
import { useStars } from '@/store/stars'

gsap.registerPlugin(ScrollToPlugin);

export const isMenuOpen = ref(false);
export const isMenuVisible = ref(false);

export const useMenu = {
  open() {

    const { body } = document
    const credits = document.querySelector('.Credits')
    const nav = document.getElementById('AppNav')
    const timelineNav = document.getElementById('TimelineNav')
    const timelineNavYears = timelineNav.querySelectorAll('.Timeline__navLink')
    const drawingsContainer = document.querySelector('.Header__drawings')
    const appContent = document.getElementById('AppContent')

    const menuOpenTL = gsap.timeline({
      paused: true,
      defaults: {
        duration: 1.2,
        ease: 'power4.out',
        overwrite: true
      },
      onStart: () => {
        body.classList.add('openMenu')
        isMenuOpen.value = true
        useStars.randomize()
      }
    })

    //Nav
    menuOpenTL.to(nav.querySelectorAll('a'), { yPercent: 0, stagger: 0.1 }, 0.5)

    //Timeline
    menuOpenTL.to(timelineNav, { yPercent: 0 }, 0.5)
    menuOpenTL.fromTo(timelineNav.querySelector('.inner'), { xPercent: -20 }, { xPercent: 0 }, 0.5)
    menuOpenTL.to(timelineNavYears, { yPercent: 0, stagger: 0.02 }, 0.5)

    //App
    menuOpenTL.to(appContent, { scrollTo: 10, ease: 'power4.in', duration: 0.6 }, 0)

    // Credits
    menuOpenTL.to(credits, { yPercent: 0 }, 1.2)
    menuOpenTL.to(credits.querySelectorAll(':scope a > *'), { yPercent: 0, stagger: 0.2 }, 1.2)
    menuOpenTL.to(credits.querySelector('.Star'), { y: 0 , x: 0, opacity: 1, rotate: 0, duration: 2, ease: 'expo.out' }, 1.2)

    // Drawings
    menuOpenTL.fromTo(drawingsContainer.querySelector('.Drawing:first-child'), { xPercent: -500, yPercent: -350, opacity: 0, rotation: -180 }, { xPercent: 0, yPercent: 0, scale: 0.7, rotation: 40, opacity: 1, duration: 1.4, delay: 0.2 }, 0.5)
    menuOpenTL.fromTo(drawingsContainer.querySelector('.Drawing[data-name="heart"]'), { xPercent: 100, yPercent: -200, opacity: 0, rotation: -40, scale: 1 }, { xPercent: 0, yPercent: 0, scale: 1, rotation: 0, opacity: 1, duration: 1.4, delay: 0.3 }, 0.5)
    menuOpenTL.fromTo(drawingsContainer.querySelector('.Drawing[data-name="note"]'), { xPercent: 400, yPercent: -400, opacity: 0, scale: 1.5 }, { xPercent: -50, yPercent: 30, scale: 0.7, rotation: 40, opacity: 1, duration: 1.4, delay: 0.4 }, 0.5)
    menuOpenTL.fromTo(drawingsContainer.querySelector('.Drawing[data-name="quotes"]'), { xPercent: -150, yPercent: 150, rotation: 15, opacity: 0, scale: 1.5 }, { xPercent: -50, yPercent: 0, rotation: -5, scale: 0.7, opacity: 1, duration: 1.4, delay: 0.5 }, 0.6)
    menuOpenTL.fromTo(drawingsContainer.querySelector('.Drawing:last-child'), { xPercent: 250, yPercent: 400, opacity: 0, rotation: 200 }, { xPercent: -100, yPercent: -100, scale: 0.8, opacity: 1, rotation: -40, duration: 1.4, delay: 0.6 }, 0.7)

    menuOpenTL.play()

  },
  close() {

    const { body } = document
    const credits = document.querySelector('.Credits')
    const nav = document.getElementById('AppNav')
    const timelineNav = document.getElementById('TimelineNav')
    const timelineNavYears = timelineNav.querySelectorAll('.Timeline__navLink')
    const drawingsContainer = document.querySelector('.Header__drawings')

    const menuCloseTL = gsap.timeline({
      paused: true,
      defaults: {
        duration: 0.6,
        ease: 'power4.in',
        overwrite: true
      },
      onStart: () => {
        window.scrollTo(0, 0)
        body.classList.remove('openMenu')
        isMenuOpen.value = false;
        useStars.randomize()
      },
      onComplete: () => drawingsContainer.classList.remove('is--showing')
    })

    //Nav
    menuCloseTL.to(nav.querySelectorAll('a'), { yPercent: 102, stagger: -0.05 }, 0)
    menuCloseTL.to(nav.querySelectorAll('a'), { yPercent: 102, stagger: -0.05 }, 0)

    //Timeline
    menuCloseTL.to(timelineNav, { yPercent: 102 }, 0)
    menuCloseTL.to(timelineNav.querySelector('.inner'), { xPercent: 0 }, 0)
    menuCloseTL.to(timelineNavYears, { yPercent: 102, stagger: 0.1 }, 0)

    // Credits
    menuCloseTL.to(credits, { yPercent: 100, duration: 0.4 }, 0)
    menuCloseTL.to(credits.querySelectorAll(':scope > a > *'), { yPercent: 102, stagger: -0.03, duration: 0.4 }, 0)
    menuCloseTL.to(credits.querySelector('.Star'), { y: -60 , x: 100, opacity: 0, rotate: 360, ease: 'expo.in', duration: 0.4 }, 0)

    // Drawings
    menuCloseTL.to(drawingsContainer.querySelector('.Drawing:first-child'), { xPercent: -500, yPercent: -350, rotation: -180 }, 0)
    menuCloseTL.to(drawingsContainer.querySelector('.Drawing[data-name="heart"]'), { xPercent: 100, yPercent: -200, rotation: -40, scale: 1 }, 0)
    menuCloseTL.to(drawingsContainer.querySelector('.Drawing[data-name="note"]'), { xPercent: 400, yPercent: -400, scale: 1.5 }, 0)
    menuCloseTL.to(drawingsContainer.querySelector('.Drawing[data-name="quotes"]'), { xPercent: -150, yPercent: 150, opacity: 0, scale: 1.5 }, 0)
    menuCloseTL.to(drawingsContainer.querySelector('.Drawing:last-child'), { xPercent: 250, yPercent: 400, rotation: 200 }, 0)

    menuCloseTL.play()

  }
}
