// import { isDEVMODE } from '@/store'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

const prllx = {
  mounted(el, { value }){

    if(value) {
      el.setAttribute('data-prllx', "")

      const { from, to, start, end } = value;

      el.prllxTL = gsap.timeline({
        paused: true,
        defaults: {
          duration: 1,
          ease: 'none'
        },
        scrollTrigger: {
          trigger: el.parentNode,
          scroller: document.getElementById('AppContent'),
          // markers: isDEVMODE.value,
          start,
          end,
          scrub: true
        }
      })

      el.prllxTL.fromTo(el, from, to)
    }

 },
 unmounted(el) {
   if (el.prllxTL) el.prllxTL.kill(true)
 }

}

export default prllx
