<template>
  <div class="TimelineYear__transition" ref="TimelineTransition">
    <div class="inner">
      <div class="Years" ref="Years">
        <div class="Year" ref="YearPrev">
          <span>{{ prevPrevYear }}</span>
          <span>{{ prevYear }}</span>
          <span>{{ currentYear.year }}</span>
        </div>
        <div class="Year" ref="YearCurrent">
          <span>{{ prevYear }}</span>
          <span>{{ currentYear.year }}</span>
          <span>{{ nextYear }}</span>
        </div>
        <div class="Year" ref="YearNext">
          <span>{{ currentYear.year }}</span>
          <span>{{ nextYear }}</span>
          <span>{{ nextNextYear }}</span>
        </div>
      </div>
    </div>

    <div class="TimelineYear__transition__drawings" ref="EventDrawings">
      <Drawing v-for="drawing in transitionDrawings.slice(0, 7)" :key="drawing" :name="drawing" />
      <BaseVisual v-for="(photo, index) in transitionPhotos.slice(0, 3)" :visual="{ src : photo }" isBG="true" :key="index" />
    </div>

  </div>
</template>

<script>
  import { ref, computed, onMounted, onBeforeUnmount } from 'vue'
  import { gsap } from 'gsap'
  import { useStars } from '@/store/stars'
  import { intro } from '@assets/config'
  import { currentYear, isYearTransition, isYearTransitionFromFutur } from '@/store/timeline'
  import Drawing from '@components/Drawing'
  import BaseVisual from '@components/BaseVisual'

  export default {
    components: { Drawing, BaseVisual },
    setup() {

      const TimelineTransition = ref(null)
      const Years = ref(null)
      const YearPrev = ref(null)
      const YearCurrent = ref(null)
      const YearNext = ref(null)
      const EventDrawings = ref(null)

      const prevYear = computed(() => Number(currentYear.value.year) - 1)
      const prevPrevYear = computed(() => Number(currentYear.value.year) - 2)
      const nextYear = computed(() => Number(currentYear.value.year) + 1)
      const nextNextYear = computed(() => Number(currentYear.value.year) + 2)

      const transitionDrawings = gsap.utils.shuffle(['k7', 'cup-main', 'cup-skip', 'eglise', 'grand-place', 'mic', 'nuit-secret', 'opera', 'photo', 'st-sauveur', 'sticks', 'ticket-01', 'ticket-02', 'ticket-03', 'tripostal', 'quotes', 'star', 'heart', 'quotes', 'star', 'heart'])
      const transitionPhotos = gsap.utils.shuffle(intro)

      const transitionDuration = 1.8
      const onEnterTL = gsap.timeline({
        defaults: {
          duration: transitionDuration,
          ease: 'power4.inOut'
        },
        paused: true,
        onStart: () => {
          // Setup Spans
          [YearPrev.value, YearCurrent.value, YearNext.value].forEach((year) => {
            const yearSpans = year.querySelectorAll('span')
            gsap.set(yearSpans[0], { yPercent: isYearTransitionFromFutur.value ? -204 : 0 })
            gsap.set(yearSpans[1], { yPercent: isYearTransitionFromFutur.value ? -102 : 102 })
            gsap.set(yearSpans[2], { yPercent: isYearTransitionFromFutur.value ? 0 : 204 })
          })
        }
      })

      onMounted(() => {
        onEnterTL.to(YearPrev.value.querySelectorAll('span'), { yPercent: isYearTransitionFromFutur.value ? "+=102" : "-=102", duration: transitionDuration, ease: 'power4.inOut' }, isYearTransitionFromFutur.value ? 0.3 : 0.1)
        onEnterTL.to(YearCurrent.value.querySelectorAll('span'), { yPercent: isYearTransitionFromFutur.value ? "+=102" : "-=102", duration: transitionDuration, ease: 'power4.inOut' }, 0.2)
        onEnterTL.to(YearNext.value.querySelectorAll('span'), { yPercent: isYearTransitionFromFutur.value ? "+=102" : "-=102", duration: transitionDuration, ease: 'power4.inOut' }, isYearTransitionFromFutur.value ? 0.1 : 0.3)

        const drawings = EventDrawings.value.querySelectorAll(':scope > *')
        const drawingsShuffle = gsap.utils.shuffle([...drawings])

        onEnterTL.fromTo( drawingsShuffle, {
          x: () => -window.innerWidth * 0.6,
          y: () => gsap.utils.random(-window.innerHeight * 0.3, window.innerHeight * 1.3),
          rotate: (index) => drawingsShuffle[index].classList.contains('Drawing') ? -60 : -30,
          scale: () => gsap.utils.random(0.6, 1)
        }, {
          x: window.innerWidth * 1.5,
          y: () => gsap.utils.random(window.innerHeight * -0.3, window.innerHeight * 0.3),
          rotate: (index) => drawingsShuffle[index].classList.contains('Drawing') ? 60 : 30,
          duration: transitionDuration * 3,
          ease: 'power2.out',
          stagger: 0.3
        }, -0.3)

        onEnterTL.from(TimelineTransition.value, { opacity: 0, duration: 0.6, ease: 'power2.in' }, 0)
        onEnterTL.from(Years.value, { scale: 0.9, ease: 'power4.out' }, 0.2)
        onEnterTL.to(Years.value, { scale: 0.9, duration: 0.8, ease: 'power4.in' }, transitionDuration * 1.2)
        onEnterTL.to(TimelineTransition.value, { opacity: 0, duration: 0.6, ease: 'power2.in', onComplete: () => {
          isYearTransition.value = false
          isYearTransitionFromFutur.value = false
          useStars.randomize()
        }}, transitionDuration)

        onEnterTL.play()
      })

      onBeforeUnmount(() => {
        onEnterTL.kill(true)
      })

      return {
        TimelineTransition,
        Years,
        YearPrev,
        YearCurrent,
        YearNext,
        nextNextYear,
        prevYear,
        currentYear,
        nextYear,
        prevPrevYear,
        transitionDrawings,
        transitionPhotos,
        EventDrawings
      }
    }
  }
</script>

<style lang="scss">
  @import "@scss/components/_TimelineYearTransition.scss";
</style>
