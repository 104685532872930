<template>
  <article class="Event__gallery" ref="EventGallery">

    <h1 ref="EventTitle" >
      <span v-html="event.title" />
      <Drawing name="heart" sprite="true"/>
    </h1>

    <swiper
      class="Event__gallery__swiper is--waiting"
      :speed="600"
      :slidesPerView="'auto'"
      :centeredSlides="true"
      :spaceBetween="160"
      :mousewheel="{ 'thresholdDelta' : 50 }"
      :scrollbar="{ el: '.Event__gallery__scrollbar', draggable: true, dragSize: 43 }"
      @swiper="setSwiperRef"
      @slideChange="onSlideChange"
      @resize="onResize"
      @touchStart="onStartDrag"
      @touchEnd="onLeaveDrag"
      @scrollbarDragStart="onStartDrag"
      @scrollbarDragEnd="onLeaveDrag"
      @setTranslate="setTranslate"
    >

      <SwiperSlide v-for="(visual, index) in EventGalleryData" :key="index">
        <BaseVisual :visual="visual" :ratio="visual.ratio" />
      </SwiperSlide>

    </swiper>

    <div class="Event__gallery__scrollbar" ref="EventScrollbar">
      <div class="inner" ref="EventScrollbarInner">
        <div class="wrapper" ref="EventScrollbarWrapper">
          <div v-for="(visual, index) in EventGalleryData" :key="index" :data-ratio="visual.ratio" />
        </div>
      </div>
    </div>

    <div class="Event__gallery__hands hands--left" ref="DrawingsLeft">
      <Hand v-for="(hand, index) in Hands" :key="hand" :index="index" :isLeft="true" :fromPage="fromPageTransition" />
      <div class="Small">
        <Drawing name="star" />
        <Drawing name="note" isSprite/>
      </div>
    </div>

    <div class="Event__gallery__hands hands--right" ref="DrawingsRight">
      <Hand v-for="(hand, index) in Hands" :key="hand" :index="index" :isRight="true" :fromPage="fromPageTransition" />
      <div class="Small">
        <Drawing name="star" />
        <Drawing name="star" />
      </div>
    </div>

  </article>
</template>

<script>
  import { ref, toRefs, onBeforeUnmount, onMounted, watch } from 'vue'
  import SwiperCore, { Mousewheel, Scrollbar } from 'swiper';
  import { useStars } from '@/store/stars'
  import { isMenuOpen } from '@/store/menu'
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import 'swiper/swiper.scss';
  import { gsap } from 'gsap'
  import { SplitText } from 'gsap/SplitText'
  import Drawing from '@components/Drawing'
  import Hand from '@components/Hand'
  import { useWindowSize } from '@vueuse/core'

  // install Swiper modules
  SwiperCore.use([Mousewheel, Scrollbar]);

  import BaseVisual from '@components/BaseVisual'

  export default {
    props: ["event"],
    components: { Swiper, SwiperSlide, BaseVisual, Drawing, Hand },
    setup(props) {

      // Refs
      const EventSwiper = ref(null)
      const EventTitle = ref(null)
      const EventGallery = ref(null)
      const EventScrollbar = ref(null)
      const EventScrollbarInner = ref(null)
      const EventScrollbarWrapper = ref(null)
      const DrawingsLeft = ref(null)
      const DrawingsRight = ref(null)
      const fromPageTransition = ref(true)

      const { width } = useWindowSize()
      // Hands positions
      const Hands = ref(Math.trunc((width.value/2)/120))

      let splitTitle


      // Get Gallery
      const { event } = toRefs(props)
      const EventGalleryData = []
      event.value.gallery.pictures.forEach((picture) => {

        let ratio = null
        if (picture.includes('_p')) ratio = 'portrait'
        if (picture.includes('_s')) ratio = 'square'

        const p = { 'src' : picture, 'ratio' : ratio }
        EventGalleryData.push(p)
        
      })

      // Events
      const onSlideChange = (slider) => {
        useStars.randomize('expo.out')

        const { activeIndex } = slider

        // Title positions after first slide
        activeIndex > 0 ?
          EventTitle.value.classList.add('is--minimal') :
          EventTitle.value.classList.remove('is--minimal')

        // Red stars rotation
        gsap.to([DrawingsLeft.value.querySelectorAll('.Drawing[data-name="star"]'), DrawingsRight.value.querySelectorAll('.Drawing[data-name="star"]')], {
          rotate: () => gsap.utils.random(-120, 120, 1),
          y: () => gsap.utils.random(-50, 50),
          x: () => gsap.utils.random(-50, 50),
          scale: () => gsap.utils.random(0.5, 1),
          stagger: { from: 'random', each: 0.05 },
          ease: 'power4.out',
          duration: 1.8
        })
        // Note
        gsap.to(DrawingsLeft.value.querySelector('.Drawing[data-name="note"'), {
          rotate: () => gsap.utils.random(-20, 20, 1),
          y: () => gsap.utils.random(-50, 50),
          x: () => gsap.utils.random(-50, 50),
          ease: 'power4.out',
          duration: 1.8
        })

        // Hands
        gsap.to([DrawingsLeft.value.querySelectorAll(':scope > .Drawing'), DrawingsRight.value.querySelectorAll(':scope > .Drawing')], {
          rotate: () => gsap.utils.random(-10, 10),
          yPercent: () => gsap.utils.random(0, 30),
          ease: 'power4.out',
          duration: 1.8
        })
      }

      const onResizeTimeOut = ref(null)
      const onResize = (swiper) => {
        swiper.$el[0].classList.add('is--waiting')

        // Hands positions
        Hands.value = Math.trunc((width.value/2)/120)

        clearTimeout(onResizeTimeOut.value)
        onResizeTimeOut.value = setTimeout(() => {
          swiper.$el[0].classList.remove('is--waiting')
        }, 250)
      }

      const onStartDrag = (swiper) => {
        swiper.el.classList.add('is--dragging')
      }

      const onLeaveDrag = (swiper) => {
        swiper.el.classList.remove('is--dragging')
      }

      const setTranslate = (swiper) => {
        const { progress } = swiper
        const percentage = (EventScrollbarWrapper.value.scrollWidth - EventScrollbarInner.value.offsetWidth ) * 100 / EventScrollbarInner.value.offsetWidth
        gsap.set(EventScrollbarWrapper.value, { xPercent: percentage * -progress })
      }

      const setSwiperRef = (swiper) => {
        EventSwiper.value = swiper
        const slides = swiper.slides.slice(0, 3)
        const slidesScrollbar = EventScrollbarWrapper.value.querySelectorAll('div')
        onEnterTL.from(slides, { x: -window.innerWidth * 1.5, stagger: -0.1, duration: 2.4 }, 0)
        onEnterTL.from(EventScrollbar.value, { xPercent: 50 }, 0)
        onEnterTL.from([...slidesScrollbar].slice(0, 14), { x: 180, stagger: 0.1 }, 0)
        onEnterTL.from(EventScrollbar.value.querySelector('.swiper-scrollbar-drag'), { scale: 1.3, opacity: 0 }, 0)
        onEnterTL.play()
      }


      // Enter Page
      const onEnterTL = gsap.timeline({
        paused: true,
        defaults: {
          duration: 1.8,
          ease: 'power4.out'
        },
        delay: 0.25,
        onStart: () => {
          // To remove delay + easings in Hand.vue (when resizing)
          fromPageTransition.value = false
        },
        onComplete: () => {
          EventSwiper.value.$el[0].classList.remove('is--waiting')
        }
      })

      onMounted(() => {

        splitTitle = new SplitText(EventTitle.value.querySelector('span'), { type: 'words, lines' })
        splitTitle.lines.forEach((line, index) => onEnterTL.from(line.querySelectorAll('div'), { yPercent: 115, duration: 2.4 }, 0.6 + index * 0.1 ))

        // Drawings
        onEnterTL.from(EventTitle.value.querySelector('.Drawing'), { xPercent: -100, yPercent: 130, scale: 0, rotate: -20, clearProps: 'all' }, 0.7)
        onEnterTL.from(DrawingsLeft.value.querySelector('.Drawing[data-name="star"]'), { xPercent: -100, yPercent: 130, opacity: 0, rotate: -90, clearProps: 'all' }, 0.7)
        onEnterTL.from(DrawingsLeft.value.querySelector('.Drawing[data-name="note"]'), { y: 250, scale: 0.2, opacity: 0, clearProps: 'all' }, 0.7)
        onEnterTL.from(DrawingsRight.value.querySelectorAll('.Drawing[data-name="star"]'), { xPercent: -100, yPercent: 130, opacity: 0, rotate: -90, stagger: { each: 0.1, from: 'random' }, clearProps: 'all' }, 0.7)
      })

      onBeforeUnmount(() => {
        onEnterTL.kill(true)
      })

      // ToggleMenu
      const onToggleMenu = () => {

        isMenuOpen.value ? EventSwiper.value.disable() : EventSwiper.value.enable()

        gsap.to(EventScrollbar.value, {
          y: isMenuOpen.value ? 40 : 0,
          opacity: isMenuOpen.value ? 0 : 1,
          ease: isMenuOpen.value ? 'power4.in' : 'expo.out',
          duration: isMenuOpen.value ? 0.4 : 1.6,
          overwrite: true,
          delay: isMenuOpen.value ? 0 : 1.2
        })

        gsap.to(EventSwiper.value.$el, {
          y: isMenuOpen.value ? window.innerHeight : 0,
          x: isMenuOpen.value ? window.innerWidth * 0.2 : 0,
          ease: isMenuOpen.value ? 'power4.in' : 'expo.out',
          duration: isMenuOpen.value ? 0.8 : 1.6,
          stagger: 0.1,
          overwrite: true,
          delay: isMenuOpen.value ? 0 : 0.7
        })

        splitTitle.lines.forEach((line, index) => {
          gsap.to(line.querySelectorAll('div'), {
            yPercent: isMenuOpen.value ? 115 : 0,
            duration: isMenuOpen.value ? 0.6 : 1.6,
            ease: isMenuOpen.value ? 'power4.in' : 'expo.out',
            overwrite: true,
            delay: isMenuOpen.value ? 0 : 0.6 + index * 0.1
          })
        })

        gsap.to([DrawingsLeft.value, DrawingsRight.value], {
          yPercent: isMenuOpen.value ? window.innerHeight * 0.5 : 0,
          ease: isMenuOpen.value ? 'power4.in' : 'expo.out',
          duration: isMenuOpen.value ? 0.8 : 1.6,
          overwrite: true,
          delay: isMenuOpen.value ? 0 : 0.7
        })

      }

      watch(isMenuOpen, onToggleMenu)

      return {
        EventTitle,
        EventGalleryData,
        EventGallery,
        setSwiperRef,
        EventScrollbar,
        EventScrollbarInner,
        EventScrollbarWrapper,
        onStartDrag,
        onLeaveDrag,
        onSlideChange,
        onResize,
        setTranslate,
        DrawingsLeft,
        DrawingsRight,
        Hands,
        fromPageTransition
      };
    },
  };
</script>

<style lang="scss">
  @import "@scss/components/WknSwiper.scss";
  @import "@scss/components/_EventGallery.scss";
</style>
