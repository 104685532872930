<template>
  <div class="LLN" ref="LLN">
    <a href="/" class="Logo">
      <div class="years"><span class="txt">20 ans de</span></div>
      <div class="lln">
        <div><span class="txt">Lillelanuit</span></div>
        <Star />
        <div class="com">
          <svg width="44" height="12" viewBox="0 0 43.33 11.23">
            <path d="M1,11.23a1,1,0,0,1-1-1.05.53.53,0,0,1,0-.13A1.46,1.46,0,0,1,1.37,8.59,1.08,1.08,0,0,1,2.43,9.7a.76.76,0,0,1,0,.15,1.36,1.36,0,0,1-1.34,1.38H1Z"/>
            <path d="M13.38,8.11a5.87,5.87,0,0,1-5,3.09c-2.15,0-3.18-1.15-3.18-3.34a8.68,8.68,0,0,1,3.16-6.6A5.23,5.23,0,0,1,11.68,0c1.91,0,2.61.93,2.61,2a1.3,1.3,0,0,1-1.15,1.43h0a.67.67,0,0,1-.65-.38.74.74,0,0,1,0-.63,2.5,2.5,0,0,0,.28-1c0-.65-.3-1.08-1.23-1.08-1.13,0-2.19.8-3.14,2.66A10.68,10.68,0,0,0,7.16,8c0,1.69.78,2.56,2,2.56,1.89,0,3.24-1.28,4.09-3.36h.1Z"/>
            <path d="M21.37,0c2.23,0,3.36,1.25,3.36,3.54a8.77,8.77,0,0,1-3.14,6.65,5,5,0,0,1-3.19,1c-1.65,0-3.49-.55-3.49-3.39A8.88,8.88,0,0,1,18.25,1a5.28,5.28,0,0,1,3.09-1Zm0,.35a3,3,0,0,0-2.43,1.48,13.88,13.88,0,0,0-2.19,6.75c0,1.66.76,2.26,1.63,2.26a2.65,2.65,0,0,0,2.14-1.08A14,14,0,0,0,23,2.44c0-1.23-.4-2.08-1.58-2.08Z"/>
            <path d="M43.33,8.39a4.67,4.67,0,0,1-3.71,2.81c-1.44,0-1.31-1.21-.83-2.54.78-2.13,1.73-4.59,2.36-6.4.27-.75.12-1.45-.58-1.45-2,0-4.62,3.74-5.67,6.73-.33.87-.7,1.85-1.13,3.36a14.74,14.74,0,0,0-2,.2c.93-2.46,2.46-6.9,2.94-8.81.18-.7.2-1.46-.58-1.46-1.7,0-4.42,3.14-5.67,6.78-.3.88-.65,1.91-1.08,3.29a17.77,17.77,0,0,0-1.93.2,87,87,0,0,0,3-9.77c.1-.32.05-.47-.27-.47H26.86l.08-.28A26.2,26.2,0,0,0,30.68,0c-.3,1.18-.9,3.29-1.26,4.45C30.75,2.54,32.71,0,35.17,0c1.58,0,1.51,1.56,1.2,2.71-.17.63-.32,1-.52,1.71C37.18,2.46,39.13,0,41.57,0a1.6,1.6,0,0,1,1.2.45c.53.6.18,1.91-.07,2.62C42,5,40.89,7.89,40.39,9.49c-.18.58-.15.9.25.9.8,0,1.93-1.23,2.46-2.31Z"/>
          </svg>
        </div>
      </div>
    </a>
    <div v-html="slogan"></div>
  </div>
</template>

<script>
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { slogan } from '@assets/config'
import { gsap } from 'gsap'
import Star from '@components/Star'

export default {
  components: { Star },
  setup() {

    const LLN = ref(null)

    const onEnterTL = gsap.timeline({
      defaults: {
        duration: 1.4,
        ease: 'power4.out',
        clearProps: 'all'
      },
      delay: 1
    })

    onMounted(() => {
      const el = LLN.value
      onEnterTL.from(el.querySelectorAll('.txt'), { yPercent: 110, stagger: 0.1 }, 0)
      onEnterTL.from(el.querySelector('.com svg'), { xPercent: -100 }, 0.3)
      onEnterTL.from(el.querySelector('.Star'), { y: -20, x: 40, rotate: 120, opacity: 0}, 0.5)
      onEnterTL.from(el.querySelector('p'), { y: 20, opacity: 0}, 0.5)
    })

    onBeforeUnmount(() => onEnterTL.kill(true))

    return {
      LLN,
      slogan
    }
  }
}
</script>

<style lang="scss">
  @import "@scss/components/_LLN.scss";
</style>
