<template>
  <div class="WknPlyr">

    <div ref="plyr" v-if="youtube">
      <iframe :src="'https://www.youtube.com/embed/'+youtube"
        allowfullscreen
        allowtransparency
        allow="autoplay"
      ></iframe>
    </div>

    <div ref="plyr" v-else-if="vimeo">
      <iframe :src="'https://player.vimeo.com/video/'+vimeo"
        allowfullscreen
        allowtransparency
        allow="autoplay"
      ></iframe>
    </div>

    <video v-else ref="plyr">
      <source :src="src" />
    </video>

    <BaseVisual class="plyr__poster" :isBG="true" :visual="{ src: poster ? poster : `https://img.youtube.com/vi/${youtube}/maxresdefault.jpg` }" @click.prevent="playVideo" />

    <button class="plyr__play">
      <div class="inner">
        <div class="subtitle" ref="plyrSubtitle"><span>{{ event.video.subtitle }}</span></div>
        <div class="title" ref="plyrTitle"><span>{{ event.title }}</span></div>
      </div>

      <time class="plyr__duration" ref="plyrDuration">
        <span>{{ videoDuration }}'</span>
        <div class="icon">
          <svg width="27" height="18" viewBox="0 0 26.46 17.63">
            <circle cx="17.65" cy="8.81" r="8.31"/>
            <g class="icon-play">
              <circle cx="8.81" cy="8.81" r="8.81"/>
              <path id="Polygone_2" data-name="Polygone 2" class="cls-3" d="M11.35,8.81,6.4,11.9V5.73Z"/>
            </g>
          </svg>
        </div>
      </time>

    </button>

    <div class="Event__plyr__drawings" ref="plyrDrawings">
      <Drawing name="note" sprite="true" />
      <Drawing name="thunderbolt" sprite="true" />
      <Drawing name="star" />
    </div>

  </div>
</template>

<script>
  import { toRef, ref, onMounted, onUnmounted } from 'vue';
  import BaseVisual from '@components/BaseVisual';
  import Plyr from 'plyr';
  import 'plyr/dist/plyr.css';
  import Drawing from '@components/Drawing'

  export default {
    components : { BaseVisual, Drawing },
    props: {
      event: { type: Object },
      poster: { type: String },
      src: { type: String },
      youtube: { type: String },
      vimeo: { type: String },
      controls: {
        type: Array,
        default : () => [ 'play', 'progress', 'mute', 'fullscreen', 'pip']
      }
    },
    setup(props) {

      const WknPlyr = ref(null)
      const plyr = ref(null)
      const plyrSubtitle = ref(null)
      const plyrTitle = ref(null)
      const plyrDuration = ref(null)
      const videoDuration = ref(null)
      const plyrDrawings = ref(null)

      const controls = toRef(props, 'controls')

      // Events

      const onPlyrReady = (evt) => {
        const { target } = evt
        setTimeout(() => target.classList.add('plyr--hide-controls'), 150)
        videoDuration.value = new Date(WknPlyr.value.duration * 1000).toISOString().substr(14, 5)
      }

      const onPlyrEnded = (evt) => {
        const { target } = evt
        target.classList.add('plyr--hide-controls')
        target.classList.add('plyr--ended')
      }

      const onPlyrPlaying = (evt) => {
        const { target } = evt
        if(target.classList.contains('plyr--seeking')) target.classList.remove('plyr--seeking')
        if(target.classList.contains('plyr--ended')) target.classList.remove('plyr--ended')
      }

      const onPlyrSeeking = (evt) => {
        const { target } = evt
        target.classList.add('plyr--seeking')
      }

      const playVideo = () => {
        WknPlyr.value.play()
      }

      // Mounted

      onMounted(() => {
        WknPlyr.value = new Plyr(plyr.value, {
          controls : controls.value,
          youtube : { noCookie: false, rel: 0, showinfo: 0, iv_load_policy: 3, modestbranding: 1, playsinline: 0 }
        })

        WknPlyr.value.on('ready', onPlyrReady)
        WknPlyr.value.on('ended', onPlyrEnded)
        WknPlyr.value.on('playing', onPlyrPlaying)
        WknPlyr.value.on('seeking', onPlyrSeeking)
      })

      onUnmounted(() => WknPlyr.value.destroy())

      return {
        plyr,
        WknPlyr,
        plyrSubtitle,
        plyrTitle,
        playVideo,
        plyrDuration,
        videoDuration,
        plyrDrawings
      }

    }
  }

</script>

<style lang="scss">
  @import "@scss/components/WknPlyr.scss";
</style>
