<template>
  <div class="Team__grid" ref="TeamGrid">
    <TeamMate v-for="(mate, index) in team" :key="index" :mate="mate" :ref="setTeamMatesRef" :isVisible="false" @click="onClickMate(index)" />

    <div class="Team__grid__drawings" ref="TeamGridDrawings" >

      <Drawing name="heart" v-prllx="{ from: {x : 0, y : 0}, to: {x : -100, y : 100}, start: 'top=0' }" sprite="true" />
      <Drawing name="note-simple" v-prllx="{ from: {x : 0, y : 0}, to: {x : -100, y : 100}, start: 'top=0' }" sprite="true" />
      <Drawing name="note" v-prllx="{ from: {x : 0, y : 0}, to: {x : -100, y : 100}, start: 'top=0' }" sprite="true" />

      <div class="DrawingsStars" v-prllx="{ from: {x : 0, y : 0}, to: {x : 100, y : 100}, start: 'top=0' }">
        <Drawing name="star" />
        <Drawing name="star" />
      </div>

      <div class="DrawingsThunderbolt" v-prllx="{ from: {x : 0, y : 0}, to: {x : 100, y : 300}, start: 'top=0' }">
        <Drawing name="thunderbolt" sprite="true" />
        <Drawing name="thunderbolt" sprite="true" />
      </div>
    </div>
  </div>
</template>

<script>
  import team from '@assets/team'
  import { teamDisplay, currentMateIndex } from '@/store/team'
  import { isMenuOpen } from '@/store/menu'
  import { ref, onMounted, onBeforeUnmount, watch } from 'vue'
  import { gsap } from 'gsap'
  import TeamMate from '@components/TeamMate'
  import Drawing from '@components/Drawing'

  export default {
    components: {
      TeamMate,
      Drawing
    },
    setup() {

      /// Refs
      const TeamGrid = ref(null)
      const TeamGridDrawings = ref(null)
      const TeamMates = []
      const setTeamMatesRef = el => {
        if (el) TeamMates.push(el)
      }

      // Events
      const toggleVisible = (isVisible) => {
        TeamMates.forEach((mate, index) => {
          if(!isVisible) mate.isVisible = isVisible
          else setTimeout(() => mate.isVisible = isVisible, index * 50)
        })
      }

      const onClickMate = (index) => {
        currentMateIndex.value = index
        teamDisplay.value = 'swiper'
      }

      //Events
      const onEnterTL = gsap.timeline({
        defaults: {
          duration: 1.2,
          ease: 'power4.out'
        }
      })

      onMounted(() => {
        toggleVisible(true);
        onEnterTL.from(TeamGridDrawings.value.querySelectorAll('.Drawing'), { opacity: 0, duration: 1.2, ease: 'power4.out', stagger: 0.03 }, 0)
        onEnterTL.from(TeamGridDrawings.value.querySelector('.Drawing[data-name="heart"]'), { yPercent: -300, xPercent: -100, duration: 2.4, ease: 'power4.out' }, 0)
        onEnterTL.from(TeamGridDrawings.value.querySelector('.Drawing[data-name="note"]'), { yPercent: 300, xPercent: 100, duration: 2.4, ease: 'power4.out' }, 0)
        onEnterTL.from(TeamGridDrawings.value.querySelectorAll('.Drawing[data-name="thunderbolt"]'), { yPercent: -100, xPercent: 100, duration: 2.4, ease: 'power4.out', stagger: 0.03 }, 0)
      })

      onBeforeUnmount(() => {
        onEnterTL.kill(true)
      })

      // ToggleMenu
      const onToggleMenu = () => {
        toggleVisible(!isMenuOpen.value)
        !isMenuOpen.value ? onEnterTL.timeScale(1).restart(true).delay(0.4) : onEnterTL.timeScale(2).reverse(1).delay(0.2)
      }
      watch(isMenuOpen, onToggleMenu)

      return {
        team,
        TeamGrid,
        TeamGridDrawings,
        setTeamMatesRef,
        onClickMate
      }
    }
  }
</script>

<style lang="scss">
  @import "@scss/components/_TeamGrid.scss";
</style>
