<template>
  <div id="Intro" class="Intro" ref="appIntro">
    <div class="inner">
      <div class="Intro__years" ref="appIntroYears">
        <span class="year">2001</span>
        <div class="line"></div>
        <div class="cards" ref="appIntroCards">
          <div></div>
          <div>
            <Star />
          </div>
          <div></div>
        </div>
        <div class="line"></div>
        <span class="year">2021</span>
      </div>
      <div class="Intro__visuals" ref="appIntroVisuals">
        <div class="Intro__visual" v-for="index in 3" :key="index">

          <div  v-if="index == 1">
            <Drawing name="heart" sprite="true"/>
            <Drawing name="quotes" sprite="true" />
          </div>

          <div v-else-if="index == 2">
            <Drawing name="thunderbolt" sprite="true" />
            <Drawing name="heart" sprite="true" />
            <Drawing name="note-simple" sprite="true" />
            <Drawing name="note" sprite="true" />
          </div>

          <div v-else>
            <div class="Stars">
              <Drawing name="star" />
              <Drawing name="star" />
              <Drawing name="star" />
            </div>
          </div>

          <BaseVisual :visual="{ src : getRandomVisual() }" isBG="true" />
        </div>
      </div>
      <div class="Intro__drawings" ref="appIntroDrawings">
        <Drawing name="k7" />
        <Drawing name="hand" />
      </div>
    </div>
    <div class="Intro__text" ref="appIntroText">
      <div class="inner">
        <Drawing name="heart" sprite="true" />
        <div v-html="slogan" />
      </div>
    </div>
  </div>
</template>

<script>
  import { ref, onMounted, onBeforeUnmount } from 'vue'
  import { gsap } from 'gsap'
  import { SplitText } from 'gsap/SplitText'
  import { isIntro } from '@/store'
  import { intro, slogan } from '@assets/config'
  import BaseVisual from '@components/BaseVisual'
  import { useStars } from '@/store/stars'
  import Star from '@components/Star'
  import Drawing from '@components/Drawing'

  gsap.registerPlugin(SplitText);

export default {
  components: {
    BaseVisual,
    Star,
    Drawing
  },
  setup() {

    const appIntro = ref(null)
    const appIntroVisuals = ref(null)
    const appIntroYears = ref(null)
    const appIntroCards = ref(null)
    const appIntroDrawings = ref(null)
    const appIntroText = ref(null)

    const introVisuals = intro
    const getRandomVisual = () => {
      const visual = introVisuals.splice(Math.floor(Math.random()*introVisuals.length), 1)
      return visual[0]
    }

    const introDuration = 1.8
    const introTL = gsap.timeline({
      paused: true,
      defaults: {
        duration: introDuration,
        ease: 'power4.out',
        delay: 0.2
      },
      onStart: () => {
        document.body.classList.remove('is--preloading')
        document.body.classList.remove('is--loading')
      }
    })

    onMounted(() => {

      const visuals = appIntroVisuals.value.querySelectorAll('.Intro__visual')
      const cards = appIntroCards.value.querySelectorAll(':scope > div')
      const lines = appIntroYears.value.querySelectorAll('.line')
      const years = appIntroYears.value.querySelectorAll('.year')
      const drawings = appIntroDrawings.value.querySelectorAll('.Drawing')
      const yearSpace = lines[0].offsetHeight + appIntroCards.value.offsetHeight / 2 + 20
      const splitSlogan = new SplitText(appIntroText.value.querySelector('p'), { type: 'lines' })
      new SplitText(appIntroText.value.querySelector('p'), { type: 'lines', linesClass: 'mask' })

      gsap.set(visuals[0], { opacity: 0 })
      gsap.set(visuals[2], { opacity: 0 })
      gsap.set(cards[0], { opacity: 0 })
      gsap.set(cards[2], { opacity: 0 })
      gsap.set(drawings[0], { opacity: 0 })
      gsap.set(drawings[1], { opacity: 0 })
      gsap.set(appIntroText.value.querySelector('.Drawing'), { opacity: 0 })

      // Visuals
      introTL.from(visuals[1], { y: window.innerHeight, rotate: () => gsap.utils.random([-25, 25]) }, 0)
      introTL.from(visuals[1].querySelector('.visual__wrapper'), { scale: 2 }, 0)
      introTL.from(visuals[0], { xPercent: 100, yPercent: 50, rotate: 20, duration: 2.6 }, 0.55)
      introTL.from(visuals[2], { xPercent: -100, yPercent: 50, rotate: -20, duration: 2.6 }, 0.65)
      introTL.set(visuals[2], { opacity: 1 }, 0.6)
      introTL.set(visuals[0], { opacity: 1 }, 0.7)

      //Drawings
      introTL.from(visuals[1].querySelector('.Drawing[data-name="thunderbolt"]'), { yPercent: -200, xPercent: 50, opacity: 0, rotate: -10, duration: 1.6 }, 0.8)
      introTL.from(visuals[1].querySelector('.Drawing[data-name="heart"]'), { scale: 0, yPercent: 40, xPercent: 40, rotate: 20 }, 0.8)
      introTL.from(visuals[1].querySelector('.Drawing[data-name="note-simple"]'), { scale: 0, yPercent: 100, xPercent: 150, rotate: -30 }, 1)
      introTL.from(visuals[1].querySelector('.Drawing[data-name="note"]'), { scale: 0, yPercent: -200, xPercent: 250, rotate: 40 }, 1.2)

      introTL.from(visuals[0].querySelector('.Drawing[data-name="quotes"]'), { scale: 0, rotate: -40, yPercent: 100, duration: 1.8 }, 0.7)
      introTL.from(visuals[0].querySelector('.Drawing[data-name="heart"]'), { scale: 0, yPercent: 40, xPercent: 40, rotate: 20 }, 0.7)

      introTL.from(visuals[2].querySelector('.Stars'), { scale: 0, yPercent: 40, xPercent: -140, rotate: 20 }, 0.8)

      introTL.from(drawings[0], { scale: 0.5, xPercent: 80, rotate: 15 }, 1.2)
      introTL.set(drawings[0], { opacity: 1 }, 1.2)

      introTL.from(drawings[1], { scale: 0.5, rotate: 5, xPercent: -20, duration: 1.6 }, 1.2)
      introTL.set(drawings[1], { opacity: 1 }, 1.2)


      //Years
      introTL.from(years[0], { y: yearSpace, ease: 'power4.inOut' }, introDuration * 0.5)
      introTL.from(years[1], { y: -yearSpace, ease: 'power4.inOut' }, introDuration * 0.5)
      introTL.from(lines[0], { scaleY: 0, ease: 'power4.inOut' }, introDuration * 0.5 + 0.25)
      introTL.from(lines[1], { scaleY: 0, ease: 'power4.inOut' }, introDuration * 0.5 + 0.25)

      // Cards
      introTL.from(cards[1], { yPercent: 80, rotate: () => gsap.utils.random([-25, 25]), ease: 'power4.out', duration: 1.8 }, introDuration )
      introTL.from(cards[1], { opacity: 0, duration: 0.8, ease: 'power4.out' }, introDuration )
      introTL.from(cards[0], { xPercent: 80, rotate: 15, scale: 0.3, ease: 'power4.out', duration: 1.8 }, introDuration + 0.2)
      introTL.from(cards[2], { xPercent: -80, rotate: -15, scale: 0.3, ease: 'power4.out', duration: 1.8 }, introDuration + 0.2)
      introTL.set(cards[0], { opacity: 1 }, introDuration + 0.3 )
      introTL.set(cards[2], { opacity: 1 }, introDuration + 0.3 )

      introTL.to(appIntro.value.querySelector(':scope > .inner'), { y: window.innerHeight * 3, ease: 'power4.in', duration: 1.6 }, introDuration * 1.6)
      introTL.to(visuals[2], { yPercent: 200, xPercent: -200,  ease: 'power4.in' }, introDuration * 1.6)
      introTL.to(visuals[0], { yPercent: 400, xPercent: 200, ease: 'power4.in' }, introDuration * 1.6)
      introTL.to(cards[0], { yPercent: 50, xPercent: 80, ease: 'power4.in', duration: 0.6 }, introDuration + 1.6)
      introTL.to(cards[2], { yPercent: 50, xPercent: -80, ease: 'power4.in', duration: 0.6 }, introDuration + 1.6)

      introTL.from(appIntroText.value, { yPercent: 20, skewY: -4, ease: 'power4.out' }, introDuration * 2.2)
      introTL.from(splitSlogan.lines, { yPercent: 102, ease: 'power4.out', stagger: 0.1 }, introDuration * 2.2)
      introTL.from(appIntroText.value.querySelector('.Drawing'), { scale: 0.5, xPercent: 80, rotate: 15 }, introDuration * 2.2)
      introTL.set(appIntroText.value.querySelector('.Drawing'), { opacity: 1 }, introDuration * 2.2)
      introTL.to(appIntroText.value, { yPercent: 50, ease: 'power4.in' }, introDuration * 3.3)

      introTL.call(() => useStars.randomize(), null, introDuration * 2 )

      introTL.to(appIntro.value, { opacity: 0, ease: 'power4.in', duration: 1.2, onComplete: () => {
        document.body.classList.remove('is--animating')
        isIntro.value = false
        useStars.randomize()
      }}, introDuration * 3.4)


      introTL.timeScale(1.2).play()

    })

    onBeforeUnmount(() => {
      introTL.kill(true)
    })

    return {
      appIntro,
      appIntroYears,
      appIntroVisuals,
      appIntroDrawings,
      appIntroCards,
      getRandomVisual,
      appIntroText,
      slogan
    }
  }
}
</script>

<style lang="scss">
  @import "@scss/components/AppIntro.scss";
</style>
