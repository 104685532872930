<template>

  <AppHeader v-if="!isIntro" />
  <CookiesBar v-if="showCookies" />

  <AppIntro v-if="isIntro" />
  <main v-else class="App" id="AppContent">
    <routerView v-slot="{ Component }">
      <PageTransition>
        <component :is="Component" :key="$route.fullPath"/>
      </PageTransition>
    </routerView>
  </main>

  <AppStars />
  <Disco v-if="isDarkMode" />

</template>

<script>
import { onMounted } from 'vue'
import { useSniffer } from '@/store/sniffer'
import { isFirstLoad, isIntro } from '@/store'
import { showCookies } from '@/store/cookies'
import { isDarkMode } from '@/store/darkmode'
import AppHeader from '@components/AppHeader'
import AppIntro from '@components/AppIntro'
import PageTransition from '@components/PageTransition'
import AppStars from '@components/AppStars'
import Disco from '@components/Disco'
import CookiesBar from '@components/CookiesBar'

export default {
  name: 'App',
  components: {
    AppHeader,
    AppIntro,
    PageTransition,
    AppStars,
    Disco,
    CookiesBar
  },
  setup() {

    onMounted(() => {
      isFirstLoad.value = false
      useSniffer.init()
    })

    return {
      isIntro,
      isDarkMode,
      showCookies
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/app.scss";
</style>
