<template>
  <nav class="Timeline__months" ref="Timeline" @mousedown="mouseDownHandler">
    <div class="inner">
      <a href="#" :key="index" v-for="(month, index) in currentYear.months" :class="{ 'is--disabled' : month.events.length == 0, 'is--active' : currentMonth && month.title == currentMonth.title }" @click.prevent="updateMonth(month)">
        <span>{{ month.title }}</span>
        <Star v-if="currentMonth && month.title == currentMonth.title" ref="CurrentStar" />
      </a>
    </div>
  </nav>
</template>

<script>
  import { currentYear, currentMonth, useTimeline } from '@/store/timeline'
  import { isMenuOpen } from '@/store/menu'
  import { ref, onMounted, onBeforeUnmount, watch, nextTick } from 'vue'
  import { gsap } from 'gsap'
  import Star from '@components/Star'

  export default {
    components: { Star },

    setup() {

      const Timeline = ref(null)
      const CurrentStar = ref(null)

      const onEnterTL = gsap.timeline({
        defaults: {
          duration: 1.8,
          ease: 'power4.out',
          clearProps: 'transform'
        },
        delay: 0.8
      })

      const updateMonth = (month) => {
        useTimeline.goToMonth(month)
      }

      const updateMonthStar = () => {
        if (CurrentStar.value) onEnterTL.kill(CurrentStar.value.$el)
        nextTick(() => {
          if (CurrentStar.value) {
            gsap.fromTo(CurrentStar.value.$el, { y: 20, rotate: 90, opacity: 0 }, { y: 0, rotate: 0, delay: 0, ease: 'power4.out', duration: 1.4, opacity: 1, clearProps: 'all', overwrite: 'all', onComplete: () => {
              onEnterTL.fromTo(CurrentStar.value.$el, { y: 40, rotate: 90 }, { y: 0, rotate: 0 }, 0)
            }})
          }
        })
      }

      onMounted(() => {
        onEnterTL.from(Timeline.value, { y: 152 }, 0)
        if (CurrentStar.value) onEnterTL.fromTo(CurrentStar.value.$el, { y: 40, rotate: 90 }, { y: 0, rotate: 0 },  0)

        // ScrollMouswheel
        Timeline.value.addEventListener('wheel', (e) => Timeline.value.scrollLeft += e.deltaY, {passive: true})
      })

      onBeforeUnmount(() => {
        onEnterTL.kill(true)
        Timeline.value.removeEventListener('wheel', (e) => Timeline.value.scrollLeft += e.deltaY, {passive: true})
      })

      const onToggleMenu = () => {
        if (isMenuOpen.value) onEnterTL.delay(0).timeScale(5).reverse(1)
        else onEnterTL.timeScale(1).delay(1).restart(true)
      }

      watch(isMenuOpen, onToggleMenu)
      watch(currentMonth, updateMonthStar)

      // Drag drag
      const pos = { left: 0, x:0 }
      const mouseDownHandler = function (e) {
        pos.left = Timeline.value.scrollLeft
        pos.x = e.clientX
        Timeline.value.addEventListener('mousemove', mouseMoveHandler);
        Timeline.value.addEventListener('mouseup', mouseUpHandler);
      }

      const mouseMoveHandler = function (e) {
        const dx = e.clientX - pos.x;
        gsap.to(Timeline.value, { scrollTo : { x : pos.left - dx, autoKill: true }, duration: 1.4, ease: 'power4.out' })

        if (!Timeline.value.classList.contains('is--dragging')) Timeline.value.classList.add('is--dragging')
      }

      const mouseUpHandler = function () {
        Timeline.value.removeEventListener('mousemove', mouseMoveHandler);
        Timeline.value.removeEventListener('mouseup', mouseUpHandler);
        Timeline.value.classList.remove('is--dragging')
      }

      return {
        Timeline,
        CurrentStar,
        currentYear,
        currentMonth,
        updateMonth,
        mouseDownHandler
      }
    }
  }
</script>

<style lang="scss">
  @import "@scss/components/_Timeline.scss";
</style>
