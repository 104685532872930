<template>
    <a href="#" class="Timeline__navLink" ref="TimelineNavLink" rel="noopener noreferrer">
      <span>{{year.year}}</span>
      <div class="count">
        <div class="line"></div>
        <div>
          <Star />
          <span>{{ year.eventsCount }}</span>
        </div>
      </div>
    </a>
</template>

<script>
  import { ref, onMounted } from 'vue'
  import { gsap } from 'gsap'
  import Star from '@components/Star'

  export default {
    components: { Star },
    props: [ "year" ],
    setup() {

      const TimelineNavLink = ref(null)

      onMounted(() => {
        gsap.set(TimelineNavLink.value, { yPercent: 102 })
      })

      return {
        TimelineNavLink
      }
    }
  }
</script>

<style lang="scss">
  @import "@scss/components/_TimelineNavLink.scss";
</style>
