<template>
  <div class="Team__mate" ref="Mate">
    <div>
      <span class="name">{{ mate.name }}</span>
    </div>
    <div>
      <span class="surname">{{ mate.surname }}</span>
    </div>
    <div>
      <span class="job">{{ mate.job }}</span>
    </div>
  </div>
</template>

<script>
  import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
  import { gsap } from 'gsap'

  export default {
    props: ["mate"],
    setup() {

      // Ref
      const isVisible = ref(false)
      const Mate = ref(null)

      //Events
      const onEnterTL = gsap.timeline({
        defaults: {
          duration: 1.2,
          ease: 'power4.out'
        },
        paused: true
      })

      onMounted(() => {
        onEnterTL.from(Mate.value, { yPercent: 101, clearProps: 'transform' }, 0)
        onEnterTL.from(Mate.value.querySelectorAll('span:not(.job)'), { yPercent: 101, stagger: 0.02 }, 0)
        onEnterTL.from(Mate.value.querySelector('span.job'), { yPercent: 101 }, 0.3)
      })

      onBeforeUnmount(() => {
        onEnterTL.kill(true)
      })

      // ToggleVisible
      const onToggleVisible = () => {
        isVisible.value ? onEnterTL.timeScale(1).restart(true).delay(0.4) : onEnterTL.timeScale(1.4).reverse(1).delay(0.2)
      }
      watch(isVisible, onToggleVisible)

      return {
        Mate,
        onEnterTL,
        isVisible
      }
    }
  }
</script>

<style lang="scss">
  @import "@scss/components/_TeamMate.scss";
</style>
