<template>
  <button class="AppNavToggle" ref="Btn" @click.prevent="toggleMenu">
    <span>Menu</span>
  </button>
</template>

<script>
import _ from 'lodash'
import { ref, onMounted, onBeforeUnmount } from 'vue'
import { isMenuOpen, useMenu } from '@/store/menu'
import { isYearTransition } from '@/store/timeline'
import { gsap } from 'gsap'

export default {
  setup() {

    const Btn = ref(null)

    const toggleMenu = () => {
      if (isYearTransition.value) return
      isMenuOpen.value ? useMenu.close() : useMenu.open()
    }

    const closeMenuIfOpen = () => {
      if(isMenuOpen.value) useMenu.close()
    }

    const onEnterTL = gsap.timeline({
      defaults: {
        duration: 1.4,
        ease: 'power4.out',
        clearProps: 'all'
      },
      delay: 1
    })

    onMounted(() => {
      const el = Btn.value
      onEnterTL.from(el, { y: -80}, 0)
      onEnterTL.from(el.querySelector(':scope > span'), { y: 40, duration: 1.6 }, 0)

      window.addEventListener('resize', _.debounce(closeMenuIfOpen, 100))
    })

    onBeforeUnmount(() => onEnterTL.kill(true))

    return {
      Btn,
      toggleMenu
    }

  }
}
</script>

<style lang="scss">
  @import "@scss/components/_AppNavToggle.scss";
</style>
