<template>
  <div class="Team__page">
    <AppBack />
    <TeamGrid v-if="teamDisplay == 'grid'" />
    <TeamSwiper v-else />
    <TeamToggle />
  </div>
</template>

<script>
import { currentMateIndex, teamDisplay } from '@/store/team'
import AppBack from '@components/AppBack'
import TeamGrid from '@components/TeamGrid'
import TeamSwiper from '@components/TeamSwiper'
import TeamToggle from '@components/TeamToggle'

export default {
  components: { AppBack, TeamGrid, TeamSwiper, TeamToggle },
  setup() {

    currentMateIndex.value = 0
    teamDisplay.value = 'slider'

    return {
      teamDisplay
    }
  }
}
</script>

<style lang="scss">
  @import "@/scss/pages/_Team.scss";
</style>
