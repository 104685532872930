<template>
  <article class="Event__stories" ref="EventStories" :data-id="event.id">
    <div class="Event__stories__pagination" ref="StoriesPaginationContainer">
      <span v-for="(visual, index) in event.story.pictures" :key="index" :ref="setStoriesPaginationRef">
        <span></span>
      </span>
    </div>
    <swiper
      :centeredSlides="true"
      :lazy="{ loadPrevNext: true }"
      :autoplay='{ "delay": delaySwiper }'
      :speed="delaySpeed"
      @swiper="setSwiperRef"
      @slideChange="onSlideChange"
      @touchStart="onTouchStart"
      @touchEnd="onTouchEnd"
    >
      <SwiperSlide v-for="(visual, index) in event.story.pictures" :key="index">
        <div class="Event__story">
          <div class="visual__container">
            <div class="visual">
              <img class="swiper-lazy" :data-src="visual">
            </div>
          </div>
        </div>
      </SwiperSlide>
    </swiper>

    <span v-if="event.story.description" class="Event__stories__description">{{event.story.description}}</span>

    <div class="Event__stories__drawings" ref="EventStoriesDrawings">
      <Drawing name="quotes" sprite="true"/>
      <Drawing name="star" />
      <Drawing name="thunderbolt" sprite="true" />
    </div>

  </article>
</template>

<script>
  import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
  import { Swiper, SwiperSlide } from 'swiper/vue'
  import { isMenuOpen } from '@/store/menu'
  import SwiperCore, { Autoplay, Lazy } from 'swiper'
  import { useStars } from '@/store/stars'
  import 'swiper/swiper.scss'
  import { gsap } from 'gsap'
  import Drawing from '@components/Drawing'

  // install Swiper modules
  SwiperCore.use([Autoplay, Lazy]);

  export default {
    props: ["event"],
    components: {
      Swiper,
      SwiperSlide,
      Drawing
    },
    setup() {

      // Refs
      const EventStories = ref(null);
      const EventStoriesDrawings = ref(null);
      const StoriesPaginationContainer = ref(null);
      const eventSwiper = ref(null);
      const StoriesPagination = []
      const setStoriesPaginationRef = el => {
        if (el) StoriesPagination.push(el)
      }

      // Options
      const delaySwiper = 5000
      const delaySpeed = 600

      // Pagination
      const paginationTL = ref(null)
      const updatePagination = (swiper) => {
        const { activeIndex } = swiper

        StoriesPagination.forEach(slide => {
          if (StoriesPagination.indexOf(slide) < activeIndex) gsap.set(slide.querySelector(':scope > span'),  { width: '100%', overwrite: true })
          if (StoriesPagination.indexOf(slide) > activeIndex) gsap.set(slide.querySelector(':scope > span'),  { width: '0%', overwrite: true })
        })

        paginationTL.value = gsap.fromTo(StoriesPagination[swiper.activeIndex].querySelector(':scope > span'), { width: '0%' }, {
          width: '100%',
          duration: delaySwiper/1000,
          overwrite: true,
          ease: 'none',
          onComplete: () => swiper.isEnd ? swiper.slideTo(0) : swiper.slideNext(delaySpeed)
        })
      }

      // Swiper Events
      const setSwiperRef = (swiper) => {
        eventSwiper.value = swiper
        updatePagination(swiper)
      }

      const onSlideChange = (swiper) => {
        updatePagination(swiper)
        useStars.randomize('expo.out')
      }

      const onTouchStart = (swiper) => {
        paginationTL.value.pause()
        swiper.autoplay.stop()
      }

      const onTouchEnd = (swiper) => {
        paginationTL.value.resume()
        swiper.autoplay.start()
      }

      const onEnterTL = gsap.timeline({
        defaults: {
          duration: 1.8,
          ease: 'power4.out'
        },
        delay: 0.2,
        repeatRefresh:true,
      })

      onMounted(() => {
        onEnterTL.from(EventStories.value, { y: window.innerHeight, rotate: () => gsap.utils.random(-25, 25) }, 0)
        onEnterTL.from(EventStoriesDrawings.value.querySelector('.Drawing[data-name="quotes"]'), { xPercent: -100, yPercent: 100, scale: 0.5, duration: 2.5, clearProps: 'all' }, 0)
        onEnterTL.from(EventStoriesDrawings.value.querySelector('.Drawing[data-name="star"]'), { yPercent: 300, rotate: -200, duration: 3, clearProps: 'all' }, 0)
        onEnterTL.from(EventStoriesDrawings.value.querySelector('.Drawing[data-name="thunderbolt"]'), { yPercent: 200, rotate: 40, duration: 2, clearProps: 'all' }, 0)
      })

      onBeforeUnmount(() => {
        paginationTL.value.kill(true)
        onEnterTL.kill(true)
      })

      // ToggleMenu
      const onToggleMenu = () => {
        if (isMenuOpen.value) {
          onEnterTL.timeScale(2).reverse(1)
          paginationTL.value.pause()
          eventSwiper.value.autoplay.stop()
        } else {
          onEnterTL.timeScale(1).restart(true)
          paginationTL.value.resume()
          eventSwiper.value.autoplay.start()
        }
      }

      watch(isMenuOpen, onToggleMenu)


      return {
        EventStories,
        EventStoriesDrawings,
        StoriesPaginationContainer,
        setStoriesPaginationRef,
        delaySpeed,
        delaySwiper,
        setSwiperRef,
        onSlideChange,
        onTouchStart,
        onTouchEnd
      };
    },
  };
</script>

<style lang="scss">
  @import "@scss/components/_EventStories.scss";
</style>
