<template>
  <button class="TimelineYear__switch" :class="{ 'is--disabled' : !isMenuVisible, 'noNext' : nextYear === null }" @click.prevent="goToNextYear" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave" ref="navToggle">
    <span ref="dateText">{{ currentYear.year }}</span>
    <span ref="dateTextHover" class="Hover">{{ nextYear ? nextYear.year : 'SOON' }}</span>
    <div class="Change" :class="{'is--disabled' : nextYear == null }">
      <Arrow />
      <span class="inner">Année<br/>suivante</span>
      <svg width="120" height="120" viewBox="0 0 247.35 247.35">
        <path d="M234,91.45l-9.52-9.53V68.45A45.58,45.58,0,0,0,178.9,22.88H165.43l-9.53-9.53a45.57,45.57,0,0,0-64.45,0l-9.53,9.53H68.45A45.57,45.57,0,0,0,22.88,68.45V81.92l-9.53,9.53a45.57,45.57,0,0,0,0,64.45l9.53,9.53V178.9a45.58,45.58,0,0,0,45.57,45.58H81.92L91.45,234a45.57,45.57,0,0,0,64.45,0l9.53-9.52H178.9a45.58,45.58,0,0,0,45.58-45.58V165.43L234,155.9A45.57,45.57,0,0,0,234,91.45Z"/>
      </svg>
    </div>
  </button>
</template>

<script>
  import { ref, watch, onMounted, onBeforeUnmount, nextTick } from 'vue'
  import { currentYear, nextYear, useTimeline, isYearTransition } from '@/store/timeline'
  import { isMenuOpen, isMenuVisible } from '@/store/menu'
  import { gsap } from 'gsap'
  import { SplitText } from 'gsap/SplitText'
  import Arrow from '@components/Arrow'

  export default {
    components : { Arrow },
    setup() {

      const navToggle = ref(null)
      const dateText = ref(null)
      const dateTextHover = ref(null)
      const splitDate = ref(null)
      const splitDateHover = ref(null)

      const goToNextYear = () => {
        if (nextYear.value) {
          isYearTransition.value = true
          useTimeline.goToYear(nextYear.value)
        }
      }

      console.log(nextYear)

      const onEnterTL = gsap.timeline({
        paused: true,
        defaults: {
          duration: 1.8,
          ease: 'power4.out',
          clearProps: 'transform',
        }
      })

      onMounted(() => {

        splitDate.value = new SplitText(dateText.value, { type: 'words, chars' })
        splitDateHover.value = new SplitText(dateTextHover.value, { type: 'words, chars' })

        onEnterTL.from(navToggle.value, { yPercent: 152 }, 0)
        onEnterTL.from(splitDate.value.chars, { yPercent: 102, stagger: 0.1 }, 0)
        gsap.set(splitDateHover.value.chars, { yPercent: 102 })

        if(isMenuVisible.value) onEnterTL.play()

      })

      onBeforeUnmount(() => {
        onEnterTL.kill(true)
      })

      const onToggleMenu = () => {
        if (isMenuOpen.value && isMenuVisible.value) onEnterTL.delay(0).timeScale(2).reverse(1)
        if (isMenuOpen.value == false && isMenuVisible.value) onEnterTL.timeScale(1).delay(0.8).restart(true)
      }

      const onShowMenu = () => {
        if (isMenuOpen.value == false && isMenuVisible.value == false ) onEnterTL.delay(0).timeScale(2).reverse(1)
        if (isMenuOpen.value == false && isMenuVisible.value ) onEnterTL.timeScale(1).delay(0.5).restart(true)
      }

      const onMouseEnter = () => {
        gsap.to(splitDate.value.chars, { yPercent: -102, stagger: 0.03, duration: 0.8, overwrite: true, ease: 'power4.out' })
        gsap.to(splitDateHover.value.chars, { yPercent: 0, stagger: 0.03, duration: 1.4, overwrite: true, ease: 'power4.out' })
      }

      const onMouseLeave = () => {
        gsap.to(splitDate.value.chars, { yPercent: 0, stagger: 0.03, duration: 1.4, overwrite: true, ease: 'power4.out' })
        gsap.to(splitDateHover.value.chars, { yPercent: 102, stagger: 0.03, duration: 0.8, overwrite: true, ease: 'power4.out' })
      }

      const updateYear = () => {
        onEnterTL.kill(splitDate.value.chars)

        nextTick(() => {
          if (!isYearTransition.value) {
            splitDate.value = new SplitText(dateText.value, { type: 'words, chars' })
            splitDateHover.value = new SplitText(dateTextHover.value, { type: 'words, chars' })
            onEnterTL.from(splitDate.value.chars, { yPercent: 102, stagger: 0.1 }, 0)
            gsap.set(splitDateHover.value.chars, { yPercent: 102 })
          }
        })
      }

      watch(isMenuOpen, onToggleMenu)
      watch(isMenuVisible, onShowMenu)
      watch(currentYear, updateYear)

      return {
        isMenuVisible,
        navToggle,
        dateText,
        dateTextHover,
        currentYear,
        nextYear,
        onMouseEnter,
        onMouseLeave,
        goToNextYear
      }
    }
  }
</script>

<style lang="scss">
  @import "@scss/components/_TimelineYearSwitch.scss";
</style>
