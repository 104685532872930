<template>
  <div class="TimelineYear__noResults" ref="noResults">
    <div class="inner">
      <Drawing name="star" />
      <p ref="noResultsText">Bin... en fait... pour <span>{{ currentYear.year }}</span>...<br/> On a des trous de mémoire...<br/> Sorry ! <span class="jiji">🙃</span></p>
    </div>

    <div class="Holes">
      <Drawing v-for="(hole, index) in holes" :key="index" :name="hole" />
    </div>

  </div>
</template>

<script>
  import { currentYear } from '@/store/timeline'
  import { isMenuOpen } from '@/store/menu'
  import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
  import { gsap } from 'gsap'
  import Drawing from '@components/Drawing'

  export default {
    components: { Drawing },
    setup() {

      const noResults = ref(null)
      const noResultsText = ref(null)

      const holes = ['blackhole-01', 'blackhole-02', 'blackhole-01', 'blackhole-02']

      const onEnterTL = gsap.timeline({
        defaults: {
          duration: 1.8,
          ease: 'power4.out',
          clearProps: 'transform',
        },
        delay: 0.5
      })

      onMounted(() => {

        onEnterTL.from(noResults.value, { yPercent: 102 }, 0)
        onEnterTL.from(noResults.value.querySelectorAll('.Drawing'), { scale: 0.5, y : () => gsap.utils.random(0, 140, 1), x : () => gsap.utils.random(-140, 140, 1), stagger: 0.03 }, 0)
        onEnterTL.from(noResultsText.value, { rotate: gsap.utils.random(-30, 30, 1) }, 0)
      })

      onBeforeUnmount(() => {
        onEnterTL.kill(true)
      })

      const onToggleMenu = () => {
        if (isMenuOpen.value) onEnterTL.delay(0).timeScale(2).reverse(1)
        else onEnterTL.timeScale(1).delay(0.8).restart(true)
      }

      watch(isMenuOpen, onToggleMenu)

      return {
        noResults,
        noResultsText,
        currentYear,
        holes
      }
    }
  }
</script>

<style lang="scss">
  @import "@scss/components/_TimelineYearNoResults.scss";
</style>
