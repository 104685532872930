<template>
  <div class="Team__card" ref="TeamCard">

    <div class="Portrait" @click="onCardClick" ref="TeamCardPortrait">
      <div class="inner">
        <div><span class="name">{{ mate.name }}</span></div>
        <div><span class="surname">{{ mate.surname }}</span></div>
        <div><span class="job">{{ mate.job }}</span></div>
        <Socials v-if="mate.socials.length >= 1" :socials="mate.socials" />
      </div>
      <div class="visual__container">
        <div class="visual">
          <img class="swiper-lazy" :data-src="mate.portrait" :alt="`${ mate.name } ${ mate.surname }`">
        </div>
      </div>
      <div v-if="mate.copyright" class="copyright">&copy; {{ mate.copyright }}</div>
      <Drawing name="quotes" sprite="true"/>
    </div>

    <blockquote ref="TeamCardQuote">
      <component :is="type" :to="type == 'router-link' ? mate.quote.link : null" :href="mate.quote.link" target='_blank'>
        <h4>Mon souvenir</h4>
        <div class="content">
          <div v-html="mate.quote.text" />
          <Drawing v-if="hasAbsoluteDrawing" :name="absoluteDrawingType" />
        </div>
        <span class="Link__arrow">
          <svg width="10" height="9" viewBox="0 0 9.31 9.04">
            <line x1="2.36" y1="1" x2="8.31" y2="1.1"/>
            <line x1="8.31" y1="1.1" x2="8.2" y2="7.05"/>
            <line x1="1" y1="8.04" x2="7.92" y2="1.41"/>
          </svg>
        </span>
      </component>
    </blockquote>


  </div>
</template>

<script>
  import _ from 'lodash'
  import { ref, toRefs, computed, watch, onMounted } from 'vue'
  import { gsap } from 'gsap'
  import { isTabletSmall } from '@/store/sniffer'
  import Socials from '@components/Socials'
  import Drawing from '@components/Drawing'

  export default {
    props: ["mate"],
    components : { Socials, Drawing },
    setup(props) {

      const TeamCard = ref(null)
      const TeamCardPortrait = ref(null)
      const TeamCardQuote = ref(null)
      const showQuote = ref(false)
      const hasAbsoluteDrawing = gsap.utils.random([true, false])
      const absoluteDrawingType = gsap.utils.random([ 'heart', 'star', 'heart'])

      const { mate } = toRefs(props)
      const isBlank = computed(() => mate.value.quote.external ? "_blank" : "_self" )
      const type = computed(() => !mate.value.quote.external ? 'router-link' : 'a' )

      const onEnterTL = gsap.timeline({
        defaults: {
          duration: 1.8,
          ease: 'power4.out',
          clearProps: 'all'
        },
        paused: true
      })

      const onCardClick = () => {
        if (isTabletSmall.value == false) return
        showQuote.value = !showQuote.value
      }

      const toggleQuoteIfOpen = () => {
        if(showQuote.value) showQuote.value = false
      }

      const toggleQuote = () => {
        if (showQuote.value) {
          gsap.to(TeamCardPortrait.value, { y: TeamCard.value.offsetHeight * 0.6, rotate: -10, scale: 0.6, ease: 'power4.out', duration: 1.2, overwrite: true })
          gsap.fromTo(TeamCardQuote.value, { y: -window.innerHeight * 2, rotate: -20 }, { y: 0, rotate: 5, ease: 'power3.out', duration: 1.2, overwrite: true, onStart: () => TeamCard.value.classList.add('showQuote'), clearProps: 'all' })
        } else {
          gsap.to(TeamCardPortrait.value, { y: 0, rotate: 0, scale: 1, ease: 'power4.inOut', duration: 1.2, overwrite: true  })
          gsap.to(TeamCardQuote.value, { y: -window.innerHeight * 2, rotate: -10, ease: 'power4.inOut', duration: 1.2, overwrite: true, onComplete: () => TeamCard.value.classList.remove('showQuote'), clearProps: 'all' })
        }
      }

      onMounted(() => {
        onEnterTL.from(TeamCard.value.querySelectorAll('.inner span'), { translateY: 101, stagger: 0.01 }, 0)
        if(TeamCard.value.querySelectorAll('.Socials a').length > 0)  onEnterTL.from(TeamCard.value.querySelectorAll('.Socials a'), { translateY: 101, stagger: 0.03 }, 0.3)

        window.addEventListener('resize', _.debounce(toggleQuoteIfOpen, 100))
      })

      watch(showQuote, toggleQuote)

      return {
        TeamCard,
        isBlank,
        type,
        TeamCardPortrait,
        hasAbsoluteDrawing,
        absoluteDrawingType,
        TeamCardQuote,
        onCardClick,
        toggleQuote,
        showQuote,
        onEnterTL
      }
    }
  }
</script>

<style lang="scss">
  @import "@scss/components/_TeamCard.scss";
</style>
