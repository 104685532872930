<template>
  <div class="visual__container" :class="{ 'has--bg' : isBG, 'is--portrait' : ratio == 'portrait', 'is--square' : ratio == 'square' }">
    <div class="visual__wrapper" v-prllx="prllx" ref="visualWrapper">

      <div v-if="video" class="visual is--video">
        <video autoplay loop mute >
          <source :src="video.src">
        </video>
      </div>

      <div v-else-if="isBG" class="visual lazyload" :data-bg="visual.src"></div>

      <div v-else class="visual" ref="visualImage">
        <img class="img-fluid lazyload" :src="visual.src" :data-src="visual.src" :alt="visual.alt" >
      </div>

    </div>
  </div>
</template>

<script>
  export default {
    props : [ 'isBG', 'video', 'visual', 'prllx', 'ratio' ]
  }
</script>

<style lang="scss">
  @import "@scss/components/_BaseVisual.scss";
</style>
