<template>
  <router-link :to="`/events/${event.id}`" class="Event__gallery__preview" ref="EventPreview" :data-event="event.id">
    <div class="Event__count" ref="EventPreviewCount">
      <span>{{ galleryLength }}</span>
      <div class="icon">
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div class="inner">
      <h3 ref="EventPreviewTitle" v-html="event.title" />
    </div>
    <div class="visuals" ref="EventPreviewVisuals">
      <BaseVisual :visual="{ src : getRandomPreview(index) }" :isBG="true" v-for="index in 2" :key="index" />
    </div>

    <div class="Event__picture__drawings" ref="EventDrawings">
      <Drawing v-for="drawing in eventDrawings.slice(0, 2)" :key="drawing" :name="drawing" :sprite="drawing !== 'star'" />
    </div>

  </router-link>
</template>

<script>
  import { ref, toRefs, watch, computed, onMounted, onBeforeUnmount } from 'vue'
  import { isMenuOpen } from '@/store/menu'
  import { gsap } from 'gsap'
  import { SplitText } from 'gsap/SplitText'
  import BaseVisual from '@components/BaseVisual'
  import Drawing from '@components/Drawing'

  export default {
    props: ["event"],
    components: { BaseVisual, Drawing },
    setup(props) {

      // Refs
      const EventPreview = ref(null);
      const EventPreviewVisuals = ref(null)
      const EventPreviewCount = ref(null)
      const EventPreviewTitle = ref(null)
      const { event } = toRefs(props)
      const EventDrawings = ref(null)
      const eventDrawings = gsap.utils.shuffle(['star', 'thunderbolt', 'note', 'heart'])

      const galleryLength = computed(() => event.value.gallery.pictures.length)

      const eventPreview = ref(event.value.gallery.preview)
      gsap.utils.shuffle(eventPreview.value)
      const getRandomPreview = (index) => {
        return eventPreview.value[index]
      }

      let splitTitle
      const onEnterTL = gsap.timeline({
        defaults: {
          duration: 1.8,
          ease: 'power4.out'
        },
        delay: 0.2,
      })


      onMounted(() => {
        splitTitle = new SplitText(EventPreviewTitle.value, { type: 'words, lines' });

        const drawings = EventDrawings.value.querySelectorAll(':scope > .Drawing')
        let drawingPrevLeft = gsap.utils.random([false, true])
        drawings.forEach(d => {
          const top = gsap.utils.random(-5, 100, 1)
          const left = [gsap.utils.random(-30, -10, 1), gsap.utils.random(110, 120, 1)]

          d.style.top = `${ top }%`
          d.style.left = `${ drawingPrevLeft ? left[0] : left[1] }%`

          drawingPrevLeft = !drawingPrevLeft

        })

        onEnterTL.from(drawings, { y: window.innerHeight * 0.5, rotate: -40, clearProps: 'none', stagger: 0.05 }, 0)

        onEnterTL.from(EventPreview.value.$el, { y: window.innerHeight * 1.5 }, 0)
        onEnterTL.from(EventPreviewVisuals.value.querySelectorAll(':scope > *')[0], { yPercent: 50, rotate: () => gsap.utils.random(-20, 20) }, 0)
        onEnterTL.from(EventPreviewVisuals.value.querySelectorAll(':scope > *')[1], { yPercent: 50, rotate: () => gsap.utils.random(-20, 20), duration: 2.2 }, 0)
        onEnterTL.from(EventPreviewCount.value, { x: 20, opacity: 0 }, 0.8)
        onEnterTL.from(EventPreviewCount.value.querySelectorAll('.icon > div'), { x: 200, stagger: { amount: 0.1, ease: 'power1.out' } }, 0.2)
        splitTitle.lines.forEach((line, index) => onEnterTL.from(line.querySelectorAll('div'), { yPercent: 102 }, 0.4 + index * 0.1 ))
      })

      onBeforeUnmount(() => {
        onEnterTL.kill(true)
      })

      // ToggleMenu
      const onToggleMenu = () => {
        if (isMenuOpen.value) onEnterTL.timeScale(2).reverse(1)
        else onEnterTL.timeScale(1).restart(true)
      }

      watch(isMenuOpen, onToggleMenu)

      return {
        EventPreview,
        EventPreviewCount,
        EventPreviewTitle,
        EventPreviewVisuals,
        EventDrawings,
        eventDrawings,
        galleryLength,
        getRandomPreview
      };
    },
  };
</script>

<style lang="scss">
  @import "@scss/components/_EventGallery.scss";
</style>
