<template>
  <div class="Credits" ref="Credit">
    <a href="https://www.wokine.com/" target="_blank" title="Wokine, Agence Digitale"><span>Création</span> <span>Wokine</span></a>
    <Star ref="Star"/>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { gsap } from 'gsap'
import Star from '@components/Star'

export default {
  components: {
    Star
  },
  setup() {

    const Credit = ref(null)
    const Star = ref(null)

    onMounted(() => {
      gsap.set(Credit.value, { yPercent: 101 })
      gsap.set(Credit.value.querySelectorAll(':scope a > *'), { yPercent: 102 })
      gsap.set(Star.value.$el, { y: -0, x: 100, opacity: 0, rotate: 360 })
    })

    return {
      Credit,
      Star
    }

  }
}
</script>

<style lang="scss">
  @import "@scss/components/_Credits.scss";
</style>
