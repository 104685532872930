import { createWebHistory, createRouter } from "vue-router";
import { trackRouter } from "vue-gtag-next";
import Home from "@/views/Home.vue";
import History from "@/views/History.vue";
import Team from "@/views/Team.vue";
import Contact from "@/views/Contact.vue";
import Event from "@/views/Event.vue";

import { title } from '@assets/config'
import { isMenuOpen, useMenu } from '@/store/menu'
import { useStars } from '@/store/stars'

const routes = [
  {
    path: "/",
    component: Home,
    meta: { title : "" },
    props : { isInNav : false }
  },
  {
    path: "/le-projet",
    component: History,
    meta: { title : "Le projet" }
  },
  {
    path: "/l-equipe",
    component: Team,
    meta: { title : "L'équipe" }
  },
  {
    path: "/contact",
    component: Contact,
    meta: { title : "Contact" }
  },
  {
    path: "/annee/:year",
    component: Home,
    props : { isInNav : false }
  },
  {
    path: "/events/:id",
    component: Event,
    props : { isInNav : false }
  },
  {
    path: "/:pathMatch(.*)*",
    redirect: '/',
    props : { isInNav : false }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior() { return { x: 0, y: 0 }},
  linkExactActiveClass : 'is--active',
});

router.afterEach(() => {
  if( isMenuOpen.value ) useMenu.close()
  useStars.randomize()
})

router.beforeEach((to, from, next) => {
  document.title = to.meta.title ? `${title} - ${to.meta.title}` : title
  next()
})

trackRouter(router);

export default router;
