<template>
  <div class="Team__swiper" ref="TeamSwiper">
    <swiper
      :initialSlide="currentMateIndex"
      :speed="1200"
      :slides-per-view="'auto'"
      :centeredSlides="true"
      :mousewheel="{ 'thresholdDelta' : 50 }"
      :lazy="false"
      :parallax="true"
      @swiper="setSwiperRef"
      @slideChange="onSlideChange"
      @resize="onResize"
      @slideChangeTransitionEnd="onSlideChanged"
      class="swiper-waiting is--waiting"
    >
      <SwiperSlide v-for="(mate, index) in team" :key="index">
        <TeamCard :mate="mate" :ref="setTeamCardsRef" />
      </SwiperSlide>
    </swiper>
  </div>
</template>

<script>
  import { ref, watch, onBeforeUnmount } from 'vue'
  import { gsap } from 'gsap'
  import team from '@assets/team'
  import { currentMateIndex } from '@/store/team'
  import { isMenuOpen } from '@/store/menu'
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import SwiperCore, { Lazy, Mousewheel, Parallax } from 'swiper';
  import 'swiper/swiper.scss';

  // install Swiper modules
  SwiperCore.use([Lazy, Mousewheel, Parallax]);

  import TeamCard from '@components/TeamCard'
  import { useStars } from '@/store/stars'

  export default {
    components: {
      Swiper,
      SwiperSlide,
      TeamCard
    },
    setup() {

      const teamSwiper = ref(null);
      const swiperInitialized = ref(false);
      const TeamCards = []
      const setTeamCardsRef = el => {
        if (el) TeamCards.push(el)
      }

      // Swiper Events
      const setSwiperRef = (swiper) => {
        teamSwiper.value = swiper

        const inViewSlides = teamSwiper.value.$el[0].querySelectorAll('.swiper-slide-active, .swiper-slide-prev, .swiper-slide-next')
        const inViewSlidesPortraits = []
        const inViewSlidesBlockquote = []
        inViewSlides.forEach(el => inViewSlidesPortraits.push(el.querySelector('.Portrait')))
        inViewSlides.forEach(el => inViewSlidesBlockquote.push(el.querySelector('blockquote')))

        onEnterTL.from(inViewSlides, { x: -window.innerWidth, y: window.innerHeight * 1.5, stagger: 0.1 }, 0)
        inViewSlides.forEach(slide => {
          onEnterTL.from(slide.querySelector('.Portrait'), { translateX: 100, rotation: -20 }, 0)
          onEnterTL.from(slide.querySelector('.Portrait .visual'), { scale: 2 }, 0)
          onEnterTL.from(slide.querySelector('blockquote'), { y: 500, x: 100, rotation: -40, scale: 0.8 }, 0)
        })

        swiper.lazy.load()
        TeamCards[swiper.activeIndex].onEnterTL.timeScale(1).restart(true).delay(1)
        document.body.classList.add('showTeamSwiper')

      }

      const onSlideChange = (swiper) => {
        useStars.randomize('expo.out')
        const prevCard = TeamCards[swiper.previousIndex]
        if(prevCard.showQuote) prevCard.showQuote = false
        TeamCards[swiper.activeIndex].onEnterTL.timeScale(1).restart(true).delay(0)
        TeamCards[swiper.previousIndex].onEnterTL.timeScale(2).reverse(1).delay(0)
      }

      const onSlideChanged = (swiper) => {
        if (swiperInitialized.value) {
          swiper.lazy.load()
          swiper.lazy.loadInSlide(swiper.activeIndex + 1)
        }
      }

      const onResizeTimeOut = ref(null)
      const onResize = (swiper) => {
        swiper.$el[0].classList.add('is--waiting')

        clearTimeout(onResizeTimeOut.value)
        onResizeTimeOut.value = setTimeout(() => {
          swiper.$el[0].classList.remove('is--waiting')
        }, 250)
      }

      // Enter Page
      const onEnterTL = gsap.timeline({
        defaults: {
          duration: 1.8,
          ease: 'power4.out',
          clearProps: 'all'
        },
        delay: 0.5,
        onStart: () => {
          teamSwiper.value.$el[0].classList.remove('is--waiting')
        },
        onComplete: () => {
          swiperInitialized.value = true
          teamSwiper.value.$el[0].classList.remove('swiper-waiting')
          teamSwiper.value.lazy.load()
          teamSwiper.value.lazy.loadInSlide(teamSwiper.value.activeIndex + 1)
        }
      })

      onBeforeUnmount(() => {
        onEnterTL.kill(true)
        document.body.classList.remove('showTeamSwiper')
      })

      // ToggleMenu
      const onToggleMenu = () => {

        isMenuOpen.value ? teamSwiper.value.disable() : teamSwiper.value.enable()

        const inViewSlides = teamSwiper.value.$el[0].querySelectorAll('.swiper-slide-active, .swiper-slide-prev, .swiper-slide-next')

        const currentCard = TeamCards[teamSwiper.value.activeIndex]
        if(currentCard.showQuote) currentCard.showQuote = false

        gsap.to(inViewSlides, {
          y: isMenuOpen.value ? window.innerHeight * 1.2 : 0,
          x: isMenuOpen.value ? window.innerWidth * 0.2 : 0,
          ease: isMenuOpen.value ? 'power3.in' : 'expo.out',
          duration: isMenuOpen.value ? 0.8 : 1.6,
          stagger: 0.1,
          overwrite: true,
          delay: isMenuOpen.value ? 0 : 0.5
        })

        inViewSlides.forEach(slide => {
          gsap.to(slide.querySelector('.Portrait'), {
            ease: isMenuOpen.value ? 'power3.in' : 'expo.out',
            duration: isMenuOpen.value ? 0.8 : 1.6,
            translateX: isMenuOpen.value ? 100 : 0,
            rotation: isMenuOpen.value ? -5 : 0,
            overwrite: true,
            delay: isMenuOpen.value ? 0 : 0.5
          })
        })
      }

      watch(isMenuOpen, onToggleMenu)

      return {
        team,
        setTeamCardsRef,
        currentMateIndex,
        teamSwiper,
        setSwiperRef,
        onSlideChange,
        onSlideChanged,
        onResize,
        onToggleMenu
      };
    },
  };
</script>

<style lang="scss">
  @import "@scss/components/_TeamSwiper.scss";
</style>
