import { ref, nextTick } from 'vue'
import { gsap } from 'gsap'
import { useStars } from '@/store/stars'

export const isDarkMode = ref(false);

export const useDarkMode = {
  toggle() {

    isDarkMode.value = !isDarkMode.value;
    isDarkMode.value ? document.body.classList.add('is--dark') : document.body.classList.remove('is--dark')

    nextTick(() => gsap.fromTo(document.querySelectorAll('.Darkmode .inner > span > span'), { yPercent: isDarkMode.value ? 50 : -50, opacity: 0 }, { yPercent: 0, opacity: 1, duration: 0.8, ease: 'power4.out', stagger: isDarkMode.value ? 0.01 : -0.01 }))

    useStars.randomize('power4.out')

  }
}
