<template>
  <div class="Partners__grid" ref="PartnersGrid">
    <Partner v-for="(partner, index) in data" :key="index" :partner="partner"/>

    <div class="Partners__hearts">
      <Drawing name="heart" sprite="true"/>
      <Drawing name="heart" sprite="true"/>
    </div>
  </div>
</template>

<script>
  import data from '@assets/partners'
  import { ref, watch, onMounted, onBeforeUnmount } from 'vue'
  // import { isDEVMODE } from '@/store'
  import { isMenuOpen } from '@/store/menu'
  import { gsap } from 'gsap'
  import { ScrollTrigger } from 'gsap/ScrollTrigger'
  import Partner from '@components/Partner'
  import Drawing from '@components/Drawing'

  export default {
    components: { Partner, Drawing },
    setup() {
      const PartnersGrid = ref(PartnersGrid)
      let inViewST

      const onEnterTL = gsap.timeline({
        defaults: {
          duration: 1.6,
          ease: 'power4.out'
        }
      })

      onMounted(() => {

        const el = PartnersGrid.value

        onEnterTL.from(PartnersGrid.value.querySelectorAll('a'), { yPercent: 101, opacity: 0, stagger:0.03 }, 0)

        inViewST = ScrollTrigger.create({
          trigger: el,
          scroller: document.getElementById('AppContent'),
          // markers: isDEVMODE,
          scrub: true,
          onEnter: () => onEnterTL.restart()
        })

      })

      onBeforeUnmount(() => {
        inViewST.kill(true)
        onEnterTL.kill(true)
      })


      const onToggleMenu = () => {
        if (!inViewST.isActive || inViewST.isActive == false) return
        if (isMenuOpen.value) onEnterTL.timeScale(2).delay(0).reverse(1)
      }

      watch(isMenuOpen, onToggleMenu)

      return {
        data,
        PartnersGrid
      }
    }
  }
</script>

<style lang="scss">
  @import "@scss/components/_PartnersGrid.scss";
</style>
