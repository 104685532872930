<template>
  <div class="Drawing" :data-name="name" :data-sprite="sprite" >
    <div class="sprite" v-if="sprite" draggable="false" />
    <img v-else-if="name=='quotes'" src="@/assets/SVG/drawing-quotes.svg" :alt="'Icone '+name" draggable="false" />
    <img v-else-if="name=='heart'" src="@/assets/SVG/drawing-heart.svg" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='star'" src="@/assets/SVG/drawing-star.svg" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='note'" src="@/assets/SVG/drawing-note.svg" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='note-simple'" src="@/assets/SVG/drawing-note-simple.svg" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='thunderbolt'" src="@/assets/PNG/drawing-thunderbolt.png" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='hand'" src="@/assets/PNG/drawing-hand.png" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='hand-rock'" src="@/assets/PNG/drawing-hand-rock.png" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='hand-rock-2'" src="@/assets/PNG/drawing-hand-rock-2.png" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='k7'" src="@/assets/PNG/drawing-k7.png" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='cup-main'" src="@/assets/PNG/drawing-cup-main.png" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='cup-skip'" src="@/assets/PNG/drawing-cup-skip.png" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='eglise'" src="@/assets/PNG/drawing-eglise.png" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='grand-place'" src="@/assets/PNG/drawing-grand-place.png" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='mic'" src="@/assets/PNG/drawing-mic.png" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='nuit-secret'" src="@/assets/PNG/drawing-nuit-secret.png" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='opera'" src="@/assets/PNG/drawing-opera.png" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='photo'" src="@/assets/PNG/drawing-photo.png" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='st-sauveur'" src="@/assets/PNG/drawing-st-sauveur.png" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='sticks'" src="@/assets/PNG/drawing-sticks.png" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='ticket-01'" src="@/assets/PNG/drawing-ticket-01.png" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='ticket-02'" src="@/assets/PNG/drawing-ticket-02.png" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='ticket-03'" src="@/assets/PNG/drawing-ticket-03.png" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='blackhole-01'" src="@/assets/PNG/blackholes_01.png" :alt="'Icone '+name"  draggable="false" />
    <img v-else-if="name=='blackhole-02'" src="@/assets/PNG/blackholes_02.png" :alt="'Icone '+name"  draggable="false" />
  </div>
</template>

<script>
export default {
  props: ["name", "sprite" ]
}
</script>

<style lang="scss">
  @import "@scss/components/_Drawing.scss";
</style>
